import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Button, Card } from 'antd';

import {
  PlusCircleOutlined,
} from '@ant-design/icons';

import List from './components/List';
import UserForm from './components/UserForm';
import { Creators as UserCreators } from '../../store/ducks/users';

import { Wrapper } from './styles';

const User = () => {
  const [editable, setEditableUser] = useState(null);
  const dispatch = useDispatch();
  const { loading, data, visible } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(UserCreators.getAllUsers());
  }, [dispatch]);

  const renderExtraButton = () => {
    return (
      <Button
        onClick={() => {
          dispatch(UserCreators.handleModalVisibility(true));
          setEditableUser(null);
        }}
        style={{ borderRadius: 20 }}
        type="primary"
        icon={<PlusCircleOutlined />}
      >
        Adicionar usuário
      </Button>
    );
  };

  return (
    <Wrapper>
      <Row justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            style={{ borderRadius: 5 }}
            title="Lista de Usuários"
            extra={renderExtraButton()}
          >
            <List
              data={data}
              loading={loading}
              handleEdit={setEditableUser}
              handleModal={(status) =>
                dispatch(UserCreators.handleModalVisibility(status))
              }
            />
          </Card>
        </Col>
      </Row>

      <UserForm visible={visible} user={editable} />
    </Wrapper>
  );
}

export default User;
