import { call, put } from 'redux-saga/effects';
import { message } from 'antd';

import api from '../../services/api';
import { Creators as Actions } from '../ducks/restaurantDocument';
import { Creators as RestaurantActions } from '../ducks/restaurants';

export function* changeDocumentStatus({ payload }) {
  try {
    const { documentId, status } = payload;

    yield call(
      api.put,
      `/restaurants/documents/${documentId}/update`,
      { status },
    );

    message.success('Status do documento alterado com sucesso!');
    yield put(Actions.changeStatusSuccess());
    yield put(RestaurantActions.getRestaurants());
  } catch (error) {
    message.error('Houve uma falha ao alterar o status do documento. Tente novamente!');
    yield put(Actions.changeStatusFailed());
  }
}
