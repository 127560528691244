import React, { useState, useEffect, useCallback } from 'react';

import {
  Rate,
  Select,
  Collapse,
} from 'antd';

const { Option } = Select;
const { Panel } = Collapse;

const QuestionRate = ({
  questionReasons,
  reasons,
  onChange,
}) => {
  const [, updateState] = useState();
  const [reasonsSelected, setReasonsSelected] = useState([]);
  const [panelSelected, setPanelSelected] = useState(1);
  const forceUpdate = useCallback(() => updateState({}), []);

  const notes = [1, 2, 3, 4, 5];

  const handleReasonChange = (answer, values) => {
    const reasons = reasonsSelected;
    reasons[answer] = values;

    onChange(reasons);
    setReasonsSelected(reasons);
    forceUpdate();
  };

  useEffect(() => {
    setPanelSelected(1);
    let reasons = [];

    if (questionReasons) {
      const reasonsFiltered = [];

      notes.forEach((value) => {
        const noteReasons = questionReasons.filter((reason) => reason.rating_star === value);
        reasonsFiltered[value] = noteReasons.map((reason) => reason.id);
      });

      reasons = reasonsFiltered;
    }

    onChange(reasons);
    setReasonsSelected(reasons);
  }, [questionReasons]);

  return (
    <Collapse accordion bordered activeKey={panelSelected} onChange={setPanelSelected}>
      {
        notes.map((value) => (
          <Panel
            key={value}
            header={`Avaliação ${value}`}
            header={(
              <>
                <span style={{ marginRight: '10px' }}>
                  Avaliação
                </span>
                <Rate
                  style={{ fontSize: 16 }}
                  defaultValue={value}
                  disabled
                />
              </>
            )}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Selecione os motivos"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={reasonsSelected[value]}
              onChange={(options) => handleReasonChange(value, options)}
            >
              {
                reasons.map((value) => (
                  <Option
                    value={value.id}
                    key={value.id}
                  >
                    {value.title}
                  </Option>
                ))
              }
            </Select>
          </Panel>
        ))
      }
    </Collapse>
  );
};

export default QuestionRate;
