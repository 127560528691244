import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Switch,
  Modal,
  Form,
  Button,
  Input,
} from 'antd';

import QuestionRate from './components/Rate';

import { Creators as QuestionCreators } from '../../../../store/ducks/questions';

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 24 },
};

const QuestionForm = ({
  visible,
  saving,
  id,
  handleSave,
  handleEdit,
  handleModal,
  reasons,
}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [disabled, setDisabled] = useState(true);
  const [activeQuestion, setActiveQuestion] = useState(false);
  const [reasonsSelected, setReasonsSelected] = useState([]);
  const { question } = useSelector((state) => state.questions);

  const notes = [1, 2, 3, 4, 5];

  const toggleActiveQuestion = () => {
    setActiveQuestion(!activeQuestion);
    setDisabled(false);
  };

  const formatReasons = () => {
    const resource = [];

    notes.forEach((answer) => {
      if (reasonsSelected[answer]) {
        reasonsSelected[answer].forEach((value) => {
          resource.push({ answer: answer, id: value });
        });
      }
    });

    return resource;
  };

  const onFinish = (values) => {
    values.active = activeQuestion;
    values.type = 'rate';
    values.reasons = formatReasons();

    if (question) {
      handleEdit({
        id: question.id,
        ...values,
      });
      question = values;
    } else {
      handleSave(values);
    }

    form.resetFields();

    setDisabled(true);
  };

  useEffect(() => {
    setDisabled(true);
    setActiveQuestion(true);

    if (visible) {
      if (question) {
        form.setFieldsValue(question);
        setActiveQuestion(question.active);
      } else {
        form.resetFields();
      }
    }
  }, [visible, form, question]);

  useEffect(() => {
    if (visible) {
      if (id) {
        dispatch(QuestionCreators.getQuestion({
          id,
        }));
      } 
    }
  }, [id]);

  return (
    <Modal
      title={question ? "Editar Pergunta" : "Cadastrar Pergunta"}
      maskClosable={false}
      centered
      visible={visible}
      onCancel={() => handleModal(false)}
      footer={[
        <Button key="back" onClick={() => handleModal(false)}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={disabled}
          loading={saving}
          onClick={() => form.submit()}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={{
          title: null,
          type: 'rate',
          active: true
        }}
        onFinish={onFinish}
        onChange={() => setDisabled(false)}
        layout="vertical"
      >
        <Form.Item
          name="title"
          label="Pergunta"
          rules={[{ required: true, message: 'A pergunta é obrigatória!' }]}
        >
          <Input maxLength="190" />
        </Form.Item>

        <Form.Item
          label="Motivos por avaliação"
        >
          <QuestionRate
            questionReasons={question?.reasons}
            reasons={reasons}
            onChange={(reasons) => {
              setReasonsSelected(reasons);
              setDisabled(false);
            }}
          />
        </Form.Item>

        <Form.Item
          name="active"
          label="Habilitar"
        >
          <Switch
            checked={activeQuestion}
            onChange={toggleActiveQuestion}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default QuestionForm;
