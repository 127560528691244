import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Form, Input, Modal, Row } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import { Creators as UserActions } from '../../store/ducks/me';
import { Creators as AuthActions } from '../../store/ducks/auth';

import { FormBox, PasswordRuleAlertUl, PasswordRuleAlertSubUl } from './styles';

const ChangePassword = ({ user, visible }) => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(UserActions.showChangePasswordModal(false));
  };

  const onFinish = (values) => {
    values.id = user.id || 0;
    dispatch(AuthActions.changePassword(values));
  };

  return (
    <Modal
      title="Alteração de senha"
      visible={visible}
      okText="Salvar"
      okButtonProps={{form:'change-password-form', key: 'submit', htmlType: 'submit'}}
      onCancel={() => handleCloseModal()}
      cancelText="Cancelar"
    >
      <FormBox>
        <Row>
          <Col xs={24} xl={24}>
            <ul style={PasswordRuleAlertUl}>
              <li>Regras de senha:
                <ul style={PasswordRuleAlertSubUl}>
                  <li>Mínimo de 6 caracteres.</li>
                  <li>Possuir pelo menos uma letra maiúscula.</li>
                  <li>Possuir pelo menos uma letra minúscula.</li>
                  <li>Possuir pelo menos um número.</li>
                  <li>Possuir pelo menos um caracter especial (@ $ ! % * ? _ - . : & #).</li>
                </ul>
              </li>
            </ul>
          </Col>
        </Row>
        <Col>
          <Form
            id='change-password-form'
            name="getin"
            initialValues={{
              remember: false,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="current_password"
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório!',
                },
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Senha atual"
                prefix={<LockOutlined style={{ marginRight: 10 }} />}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório!',
                },
                () => ({
                  validator(rule, value) {
                    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?\.\:&#\-\_])[A-Za-z\d@$!%*?\.\:&#\-\_]{6,}$/;
                    if (!value || (regex.test(value))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('A senha não contém os requisitos mínimos necessários.'));
                  },
                }),

              ]}
            >
              <Input.Password
                size="large"
                placeholder="Nova senha"
                prefix={<LockOutlined style={{ marginRight: 10 }} />}
              />
            </Form.Item>

            <Form.Item
              name="password_confirmation"
              rules={[
                {
                  required: true,
                  message: 'O campo é obrigatório!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('As senhas inseridas não correspodem!'));
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Confirmar senha"
                prefix={<LockOutlined style={{ marginRight: 10 }} />}
              />
            </Form.Item>
          </Form>
        </Col>
      </FormBox>
    </Modal>
  );
};

export default ChangePassword;
