import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Steps, Col, Row, Divider, Spin } from 'antd';
import { CarOutlined } from '@ant-design/icons';

import Delivery from './tabs/Delivery';
import { Wrapper } from './styles';

import { Creators as SettingsCreators } from '../../store/ducks/settings';

const { Step } = Steps;

const Config = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.settings);
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      name: 'Delivery',
      component: <Delivery current={current} setCurrent={setCurrent} />,
    },
  ];

  useEffect(() => {
    dispatch(SettingsCreators.getSettings());
  }, [dispatch]);

  return (
    <Wrapper>
      <Row>
        <Col
          span={24}
          style={{ backgroundColor: 'white', borderRadius: 5, height: '100%', margin: 0 }}
        >
          <Spin spinning={loading} tip="Carregando informações...">
            <Steps
              type="navigation"
              style={{ margin: 20 }}
              current={current}
              onChange={(newCurrent) => setCurrent(newCurrent)}
              className="site-navigation-steps"
            >
              <Step
                title="Delivery"
                icon={<CarOutlined />}
                description="Configurações globais para frete"
              />
            </Steps>
          </Spin>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ backgroundColor: 'white' }}>
          <Spin spinning={loading} tip="Carregando informações...">
            <Divider />
            {steps[current].component}
          </Spin>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Config;
