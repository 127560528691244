import React from 'react';

import {
  Row,
  Col,
  Card,
  Badge,
  Divider,
  Spin,
} from 'antd';

import * as Constants from '../../../../../../../../constants/orders';

const DeliveryInfo = ({
  loading,
  estimatedInfo,
  order,
}) => {
  const handleEstimatedTimeStatus = () => {
    if (loading) {
      return (<Spin />);
    }

    const props = {
      status: "processing",
      text: (
        <>
          {estimatedInfo?.data?.duration}
          {' '}
          ({estimatedInfo?.data?.distance})
        </>
      )
    };

    if (!estimatedInfo?.success) {
      props.status = "error";
      props.text = (
        <>
          Não foi possível calcular.
        </>
      );
    }

    return <Badge {...props} />;
  };

  const handleEstimatedTime = () => {
    const layoutTimeStatus = handleEstimatedTimeStatus();

    switch (order?.status_id) {
      case Constants.STATUS_READY_FOR_PICKUP:
        return (
          <div>
            <b>Previsão de chegada ao restaurante:</b>
            <br />
            {layoutTimeStatus}
          </div>
        );

      case Constants.STATUS_DISPATCHED:
        return (
          <div>
            <b>Previsão de chegada ao consumidor:</b>
            <br />
            {layoutTimeStatus}
          </div>
        );

      default:
        return null;
    }
  };

  if (!order) {
    return null;
  }

  return (
    <Col sm={24} md={12} xl={24}>
      <Card
        style={{
          marginBottom: 10,
        }}
        type="inner"
        size="small"
        title="Informações do entregador"
      >
        <Row>
          {order?.delivery_info && (
            <Col md={12} sm={24} xl={12}>
              <Row>
                <Col>
                  <img
                    height="80"
                    src={order?.delivery_info.driver_photo}
                    alt="Entregador"
                  />
                  <Divider style={{ height: '80%' }} type="vertical" />
                </Col>
                <Col>
                  <strong>{order?.delivery_info.driver_name}</strong>
                  <br />
                  <b>Placa:</b> {order?.delivery_info.driver_license_plate}
                  <br />
                  <b>Telefone:</b> {order?.delivery_info.driver_phone}
                </Col>
              </Row>
            </Col>
          )}

          {estimatedInfo && (
            <Col md={12} sm={24} xl={12}>
              <Row>
                <Col>
                  {handleEstimatedTime()}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Card>
    </Col>
  );
};

export default DeliveryInfo;
