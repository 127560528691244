import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Row,
  Col,
  Card,
  Divider,
  Switch,
} from 'antd';

import { Creators as MonitoringActions } from '../../../../store/ducks/monitoring';

import { Wrapper } from '../../../../styles/pages';
import List from './components/List';
import Filter from './components/Filter';

import {
  FILTER_SHOULD_BE_OPEN,
} from '../../../../constants/monitoring';

import { listen } from '../../../../firebase/session';

const Monitoring = () => {
  const dispatch = useDispatch();

  const {
    restaurants,
    loading,
  } = useSelector((state) => state.monitoring);

  const [autoRefresh, setAutoRefresh] = useState(false);
  const [filterSelected, setFilterSelected] = useState(FILTER_SHOULD_BE_OPEN);

  const handleFilter = (filter) => {
    return dispatch(MonitoringActions.getRestaurants(filter));
  };

  useEffect(() => {
    handleFilter(filterSelected);
  }, []);

  useEffect(() => {
    const unsubscribe = listen.onSnapshot(() => {
      if (autoRefresh) {
        handleFilter(filterSelected);
      }
    });

    return () => unsubscribe();
  }, [autoRefresh, filterSelected]);

  return (
    <Wrapper>
      <Card
        bordered={false}
        style={{ borderRadius: 5 }}
        title="Monitoramento"
        extra={(
          <>
            <Switch
              onChange={setAutoRefresh}
              style={{
                marginRight: 10,
              }}
            />
            Atualização automática
          </>
        )}
      >
        <Filter
          onChange={(value) => {
            handleFilter(value);
            setFilterSelected(value);
          }}
          loading={loading}
        />
        <Divider dashed />
        <Row gutter={16}>
          <Col sm={24} md={24} lg={24}>
            <List restaurants={restaurants} />
          </Col>
        </Row>
      </Card>
    </Wrapper>
  );
};

export default Monitoring;
