import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactToPrint from 'react-to-print';

import {
  CarOutlined,
  CheckOutlined,
  CloseOutlined,
  EnvironmentOutlined,
  PrinterFilled,
  SmileOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Card, Result } from 'antd';

import { Wrapper, Content, CustomTag, CustomTable as Table } from './styles';
import { orders, items } from '../columns';

import { Creators as OrderActions } from '../../../../../../store/ducks/orders';
import { Creators as DeliveryActions } from '../../../../../../store/ducks/deliveryService';

import ComponentToPrint from '../../../../../../components/OrderPrinting';
import * as Constants from '../../../../../../constants/orders';

const expandedRowRender = (order) => (
  <div
    style={{
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}
  >
    <Table
      style={{ maxHeight: 300, overflow: 'auto', padding: 30, paddingTop: 10, width: '100%' }}
      className="nested-order-table"
      columns={items}
      rowKey={(record) => record.id}
      dataSource={order.items}
      pagination={false}
    />
  </div>
);

const PendentOrders = ({ status }) => {
  const dispatch = useDispatch();

  const {
    cancelDeliveryOrder,
    geoLocation,
    deliverySpinners,
  } = useSelector((state) => state.delivery);

  const [showSpinCancelDelivery, setShowSpinCancelDelivery] = useState([]);
  const [showSpinMap, setShowSpinMap] = useState([]);
  const printerOrderRef = useRef([]);
  const printerButtonRef = useRef([]);

  const {
    [status]: data,
    loading,
    orderPrinting,
  } = useSelector((state) => state.orders);
  const { data: restaurant } = useSelector((state) => state.restaurants);

  const placeOrderForDeliveryService = (order) => {
    dispatch(DeliveryActions.placeDeliveryOrder(order));
  };

  const cancelDeliveryService = (order) => {
    if (setShowSpinCancelDelivery[order.id]) return;

    const spinArr = [...showSpinCancelDelivery];
    spinArr[order.id] = true;
    setShowSpinCancelDelivery(spinArr);

    dispatch(DeliveryActions.cancelDeliveryOrder(order));
  };

  useEffect(() => {
    if (typeof cancelDeliveryOrder === 'undefined' || cancelDeliveryOrder.length === 0) {
      return;
    }

    setShowSpinCancelDelivery([]);
    dispatch(OrderActions.getOrders());
  }, [cancelDeliveryOrder, dispatch]);

  const trackingOrder = (order) => {
    if (showSpinMap[order.id]) return;

    const spinTrack = [...showSpinMap];
    spinTrack[order.id] = true;
    setShowSpinMap(spinTrack);

    dispatch(DeliveryActions.setModalGeoLocationClosed(false));
    dispatch(DeliveryActions.geoLocation(order));
  };

  useEffect(() => {
    if (!geoLocation || geoLocation.length === 0) {
      return;
    }

    setShowSpinMap([]);
  }, [geoLocation]);

  const canShowMapButton = (order) => {
    return (
      (
        [
          Constants.STATUS_READY_FOR_PICKUP,
          Constants.STATUS_DISPATCHED,
        ].indexOf(order.status_id) != -1 && 
        order.delivery_info && 
        order.delivery_info.type_id === Constants.DELIVERY_PARTNER_LALAMOVE
      )
    );
  };

  const columns = [
    ...orders,
    {
      title: 'Ações',
      key: 'cook',
      responsive: ['sm', 'md', 'lg'],
      width: '100px',
      render: (_, record) => (
        <div style={{ padding: 8 }}>
          {canShowMapButton(record) && <CustomTag
            color="orange"
            onClick={() => trackingOrder(record)}
            icon={(showSpinMap[record.id]) && <SyncOutlined spin />}
          >
            <EnvironmentOutlined style={{ fontSize: 13 }} /> &nbsp;
            MOSTRAR MAPA
          </CustomTag>}

          <div>
            <ReactToPrint
              trigger={() => (
                <CustomTag
                  color="blue"
                  ref={(el) => (printerButtonRef.current[record.id] = (el))}
                >
                  <PrinterFilled style={{ fontSize: 13 }}/>
                  &nbsp;IMPRIMIR&nbsp;
                </CustomTag>
              )}
              content={() => printerOrderRef.current[record.id]}
            />

            <div style={{ overflow: 'hidden', height: 0 }}>
              <ComponentToPrint order={record} ref={el => (printerOrderRef.current[record.id] = el)}/>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const renderPendentOrders = () => {
    if (data.length === 0) {
      return (
        <Result
          status="404"
          title="Ops! Sua lista de pedidos esta vazia..."
          subTitle="A lista encontra-se vazia no momento."
        />
      );
    }

    return (
      <Table
        columns={columns}
        loading={loading}
        locale={{
          emptyText: 'Nenhum pedido encontrado',
        }}
        rowKey="id"
        className="order-table"
        expandable={{ expandedRowRender }}
        pagination={false}
        dataSource={data}
      />
    );
  };

  return (
    <Wrapper span={24}>
      <Card style={{ borderRadius: 5 }} title={Constants.STATUS_DESCRIPTIONS_FOR_TABS[status]}>
        <Content>{renderPendentOrders()}</Content>
      </Card>
    </Wrapper>
  );
};

export default PendentOrders;
