import React, { useState, useEffect } from 'react';

import {
  Modal,
  Form,
  Button,
  Input,
} from 'antd';

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 24 },
};

const ReasonForm = ({
  visible,
  reason,
  saving,
  handleSave,
  handleEdit,
  handleModal,
}) => {
  const [form] = Form.useForm();

  const [disabled, setDisabled] = useState(true);

  const onFinish = (values) => {
    if (reason) {
      handleEdit({
        id: reason.id,
        reasons: [],
        ...values,
      });
    } else {
      handleSave({
        ...values,
        reasons: []
      });
    }

    setDisabled(true);
  };

  useEffect(() => {
    setDisabled(true);

    if (visible) {
      if (reason) {
        form.setFieldsValue(reason);
      } else {
        form.resetFields();
      }
    }
  }, [visible, form]);

  return (
    <Modal
      title={reason ? "Editar Motivo" : "Cadastrar Motivo"}
      maskClosable={false}
      centered
      visible={visible}
      onCancel={() => handleModal(false)}
      footer={[
        <Button key="back" onClick={() => handleModal(false)}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={disabled}
          loading={saving}
          onClick={() => form.submit()}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={{
          title: null,
        }}
        onFinish={onFinish}
        onChange={() => setDisabled(false)}
        layout="vertical"
      >
        <Form.Item
          name="title"
          label="Motivo"
          rules={[
            { required: true, message: 'O motivo é obrigatório!' },
            { max: 40, message: 'O campo deve conter apenas 40 caracteres' },
          ]}
        >
          <Input maxLength="40" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReasonForm;
