import Immutable from 'seamless-immutable';

export const Types = {
  GET_RESTAURANT_MENU_REQUEST: 'menu/GET_RESTAURANT_MENU_REQUEST',
  GET_RESTAURANT_MENU_SUCCESS: 'menu/GET_RESTAURANT_MENU_SUCCESS',
  GET_RESTAURANT_MENU_FAILED: 'menu/GET_RESTAURANT_MENU_FAILED',
};

const INITIAL_STATE = Immutable({
  restaurantMenus: [],
  loading: false,
});

export default function me(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_RESTAURANT_MENU_REQUEST: {
      return {
        ...state,
        loading: true,
        restaurantMenus: [],
        menuItems: [],
      };
    }

    case Types.GET_RESTAURANT_MENU_SUCCESS: {
      return {
        ...state,
        loading: false,
        restaurantMenus: action.payload,
      };
    }

    case Types.GET_RESTAURANT_MENU_FAILED: {
      return {
        ...state,
        loading: false,
        restaurantMenus: [],
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getRestaurantMenu: (payload) => ({
    type: Types.GET_RESTAURANT_MENU_REQUEST,
    payload,
  }),

  getRestaurantMenuSuccess: (payload) => ({
    type: Types.GET_RESTAURANT_MENU_SUCCESS,
    payload,
  }),

  getRestaurantMenuFailed: () => ({
    type: Types.GET_RESTAURANT_MENU_FAILED,
  }),
};
