import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as theme from './styles/themes';
import GlobalStyle from './styles/global';
import './configs/reactotron';
import history from './routes/history';
import SoundNotification from './components/SoundNotification';

import { store, persistor } from './store';
import Routes from './routes';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme.light}>
          <GlobalStyle />
          <Routes />
          <SoundNotification />
        </ThemeProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default App;
