import Immutable from 'seamless-immutable';
import * as Constants from '../../constants/orders';

import { isExpired } from '../../configs/utils';

export const Types = {
  GET_ORDERS_REQUEST: 'orders/GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS: 'orders/GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILED: 'orders/GET_ORDERS_FAILED',

  CHANGE_STATUS_REQUEST: 'orders/CHANGE_STATUS_REQUEST',
  CHANGE_STATUS_SUCCESS: 'orders/CHANGE_STATUS_SUCCESS',
  CHANGE_STATUS_FAILED: 'orders/CHANGE_STATUS_FAILED',

  SET_QTY_PER_STATUS: 'orders/SET_QTY_PER_STATUS',
};

const INITIAL_STATE = Immutable({
  loading: false,
  data: [],
  waitingConfirmation: [],
  waitingDelivery: [],
  deliveryRoute: [],
  deliveried: [],
  canceled: [],
});

export default function orders(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_ORDERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_ORDERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        waitingConfirmation: action.payload.filter(
          (order) => [
            Constants.STATUS_CREATED,
          ].indexOf(order.status_id) !== -1 && !isExpired(order.created_at),
        ),
        waitingDelivery: action.payload.filter(
          (order) => [
            Constants.STATUS_CONFIRMED,
            Constants.STATUS_READY_FOR_PICKUP,
          ].indexOf(order.status_id) !== -1,
        ),
        deliveryRoute: action.payload.filter(
          (order) => [
            Constants.STATUS_DISPATCHED,
          ].indexOf(order.status_id) !== -1,
        ),
        deliveried: action.payload.filter(
          (order) => [
            Constants.STATUS_CONCLUDED,
          ].indexOf(order.status_id) !== -1,
        ),
        canceled: action.payload.filter(
          (order) => [
            Constants.STATUS_CANCELLED,
          ].indexOf(order.status_id) !== -1,
        ),
      };
    }
    case Types.CHANGE_STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.CHANGE_STATUS_SUCCESS: {
      state.data.find(x => x.id === action.payload.id).status_id = action.payload.status_id;

      if (action.payload.status_id === Constants.STATUS_READY_FOR_PICKUP) {
        state.data.find(x => x.id === action.payload.id).delivery_tax = action.payload.delivery_tax;
      }

      return {
        ...state,
        loading: false,
        waitingConfirmation: state.data.filter(
          (order) => [
            Constants.STATUS_CREATED,
          ].indexOf(order.status_id) !== -1 && !isExpired(order.created_at),
        ),
        waitingDelivery: state.data.filter(
          (order) => [
            Constants.STATUS_CONFIRMED,
            Constants.STATUS_READY_FOR_PICKUP,
          ].indexOf(order.status_id) !== -1,
        ),
        deliveryRoute: state.data.filter(
          (order) => [
            Constants.STATUS_DISPATCHED,
          ].indexOf(order.status_id) !== -1,
        ),
        deliveried: state.data.filter(
          (order) => [
            Constants.STATUS_CONCLUDED,
          ].indexOf(order.status_id) !== -1,
        ).reverse(),
        canceled: state.data.filter(
          (order) => [
            Constants.STATUS_CANCELLED,
          ].indexOf(order.status_id) !== -1,
        ).reverse(),
      };
    }
    case Types.CHANGE_STATUS_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }
    default:
      return state;
  }
}

export const Creators = {
  getOrders: () => ({
    type: Types.GET_ORDERS_REQUEST,
  }),
  getOrdersSuccess: (payload) => ({
    type: Types.GET_ORDERS_SUCCESS,
    payload,
  }),
  getOrdersFailed: (payload) => ({
    type: Types.GET_ORDERS_FAILED,
    payload,
  }),
  changeStatus: (payload) => ({
    type: Types.CHANGE_STATUS_REQUEST,
    payload,
  }),
  changeStatusSuccess: (payload) => ({
    type: Types.CHANGE_STATUS_SUCCESS,
    payload,
  }),
  changeStatusFailed: (payload) => ({
    type: Types.GET_ORDERS_FAILED,
    payload,
  })
};
