import React, { useState, useEffect } from 'react';

import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  withScriptjs,
  withGoogleMap,
} from 'react-google-maps';

const Map = ({
  origin,
  destination,
  markers,
  onLoadEstimatedTime,
  beforeLoadEstimatedTime,
}) => {
  const DirectionsService = new window.google.maps.DirectionsService();

  const [directions, setDirections] = useState('');

  const getRoute = async () => {
    beforeLoadEstimatedTime();

    DirectionsService.route(
      {
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        }

        onLoadEstimatedTime({
          success: status === window.google.maps.DirectionsStatus.OK,
          data: {
            duration: result?.routes[0]?.legs[0]?.duration?.text,
            distance: result?.routes[0]?.legs[0]?.distance?.text,
          },
        });
      }
    );
  }

  useEffect(() => {
    getRoute();

    return () => {
      setDirections('');
    };
  }, [origin, destination]);

  const preventRobot = () => {
    const head = document.getElementsByTagName('head')[0];
    const { insertBefore } = head;

    head.insertBefore = (newElement, referenceElement) => {
      if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com/css?family=Roboto') > -1) {
        return true;
      }

      insertBefore.call(head, newElement, referenceElement);
      return true;
    };

    return true;
  };

  return (
    <section>
      <GoogleMap defaultZoom={10} defaultCenter={origin}>
        {markers.map((marker, key) => (
          <Marker
            mapContainerStyle={{ height: '400px' }}
            key={key}
            animation={window.google.maps.Animation.DROP}
            icon={{
              origin: window.google.maps.Point(0, 0),
              anchor: window.google.maps.Point(0, 0),
              size: window.google.maps.Size(20, 20),
              ...marker.icon
            }}
            {...marker}
          />
        ))}

        {preventRobot() && directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              polylineOptions: {
                icons: [{
                  icon: {
                    path: 'M 0,-1 0,1',
                    strokeOpacity: 1,
                    scale: 4,
                  },
                  offset: '0',
                  repeat: '20px'
                }],
                strokeOpacity: 0,
                strokeColor: '#D50032',
              },
              suppressMarkers: true,
            }}
          />
        )}
      </GoogleMap>
    </section>
  );
};

export default withScriptjs(withGoogleMap(Map));
