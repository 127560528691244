import styled from 'styled-components';
import { Layout, Tabs } from 'antd';

export const Wrapper = styled(Layout)`
  flex: 1;
  height: calc(100vh - 200px);
  overflow: auto;
`;

export const TabPainel = styled(Tabs)`
  .ant-tabs-tab {
    margin: 0 22px 0 0;
  }

  .ant-tabs-nav-list {
    margin: 0px !important;
  }

  .ant-tabs-bar {
    padding-top: 20px;
    padding-left: 35px;
    margin-bottom: 0px;
  }

  .ant-tabs-tab-btn {
    margin-top: 5px;
    padding: 10px 7px;
    border-radius: 8px;
  }

  [aria-controls*='panel-waitingConfirmation'],
  [aria-controls*='panel-deliveryManOnWithdrawalPoint'] {
    background-color: #ff9900;
    animation: mymove 2s infinite;
    color: #fff !important;
  }

  [aria-controls*='panel-waitingConfirmation'][data-count*='0'],
  [aria-controls*='panel-deliveryManOnWithdrawalPoint'][data-count*='0'] {
    background-color: unset;
    animation: none;
    color: inherit !important;
  }

  @keyframes mymove {
    from {background-color: #ff9900;}
    to {background-color: #CB7B04;}
  }
`;
