import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import api from '../../services/api';
import { Creators as CustomerActions } from '../ducks/customers';

export function* getCustomers() {
  try {
    const { data, status } = yield call(
      api.get,
      `/customers`,
    );
    if (status === 200) {
      yield put(CustomerActions.getCustomersSuccess(data.data));
    }
  } catch (error) {
    yield put(CustomerActions.getCustomersFailed(error.message));
  }
}

export function* editCustomer({ payload }) {
  try {
    const { data, status } = yield call(api.put, `/customers/${payload.id}`, payload);

    if (status === 200) {
      message.success('O consumidor foi alterado com sucesso!');
      yield put(CustomerActions.editCustomerSuccess(data.resource));
      yield put(CustomerActions.handleModalVisibility(false));
    }
  } catch (error) {
    message.error('Houve um erro ao editar o consumidor, tente novamente mais tarde!');
    yield put(CustomerActions.editCustomerFailed());
  }
}
