import Immutable from 'seamless-immutable';

export const Types = {
  GET_RESTAURANTS_REQUEST: 'monitoring/GET_RESTAURANTS_REQUEST',
  GET_RESTAURANTS_SUCCESS: 'monitoring/GET_RESTAURANTS_SUCCESS',
  GET_RESTAURANTS_FAILED: 'monitoring/GET_RESTAURANTS_FAILED',

  REFRESH_SESSIONS: 'monitoring/REFRESH_SESSIONS',
  REFRESH_SESSIONS_SUCCESS: 'monitoring/REFRESH_SESSIONS_SUCCESS',
  REFRESH_SESSIONS_FAILED: 'monitoring/REFRESH_SESSIONS_FAILED',
};

const INITIAL_STATE = Immutable({
  loading: false,
  restaurants: [],
});

export default function monitoring(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_RESTAURANTS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_RESTAURANTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        restaurants: action.payload,
      };
    }

    case Types.GET_RESTAURANTS_FAILED: {
      return {
        ...state,
        loading: false,
        restaurants: [],
      };
    }

    case Types.REFRESH_STATUS: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.REFRESH_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        restaurants: action.payload,
      };
    }

    case Types.REFRESH_STATUS_FAILED: {
      return {
        ...state,
        loading: false,
        restaurants: [],
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getRestaurants: (payload) => ({
    type: Types.GET_RESTAURANTS_REQUEST,
    payload,
  }),

  getRestaurantsSuccess: (payload) => ({
    type: Types.GET_RESTAURANTS_SUCCESS,
    payload,
  }),

  getRestaurantsFailed: (payload) => ({
    type: Types.GET_RESTAURANTS_FAILED,
    payload,
  }),

  refreshStatus: (payload) => ({
    type: Types.REFRESH_STATUS_REQUEST,
    payload,
  }),

  refreshStatusSuccess: (payload) => ({
    type: Types.REFRESH_STATUS_SUCCESS,
    payload,
  }),

  refreshStatusFailed: (payload) => ({
    type: Types.REFRESH_STATUS_FAILED,
    payload,
  }),
};
