import React from 'react';

import * as Antd from 'antd';

import Document from './Document';

const Modules = (restaurant) => (
  <Antd.Row justify="center">
    {restaurant.documents && restaurant.documents.length > 0 && (
      <Antd.Col style={{ marginTop: 15 }} span={23}>
        <Document documents={restaurant.documents} />
      </Antd.Col>
    )}
  </Antd.Row>
);

export default Modules;
