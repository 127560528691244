import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

/**
 * Roles
 */
import {
  ROLE_MASTER,
  ROLE_SUPPORT,
  ROLE_MARKETING,
} from '../constants/roles';

/**
 * Pages
 */
import Login from '../pages/Login';
import ResetPassword from '../pages/ResetPassword';
import Header from '../components/Header';
import Restaurants from '../pages/Restaurants';
import Questions from '../pages/Questions';
import Coupons from '../pages/Coupons';
import User from '../pages/User';
import MonitoringPage from '../pages/Monitoring';
import Config from '../pages/Config';

const privateRoutes = [
  {
    path: '/',
    component: Restaurants,
    exact: true,
    authorize: [
      ROLE_MASTER,
      ROLE_MARKETING,
    ],
  },
  {
    path: '/restaurants',
    component: Restaurants,
    exact: true,
    authorize: [
      ROLE_MASTER,
      ROLE_MARKETING,
    ],
  },
  {
    path: '/coupons',
    component: Coupons,
    authorize: [
      ROLE_MASTER,
      ROLE_MARKETING,
    ],
  },
  {
    path: '/questions',
    component: Questions,
    authorize: [
      ROLE_MASTER,
      ROLE_MARKETING,
    ],
  },
  {
    path: '/users',
    component: User,
    authorize: [
      ROLE_MASTER,
    ],
  },
  {
    path: '/monitoring',
    component: MonitoringPage,
    authorize: [
      ROLE_MASTER,
      ROLE_MARKETING,
      ROLE_SUPPORT,
    ],
  },
  {
    path: '/settings',
    component: Config,
    authorize: [
      ROLE_MASTER,
      ROLE_MARKETING,
      ROLE_SUPPORT,
    ],
  },
];

const Routes = () => (
  <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
    <Switch>
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute path="/reset-password" component={ResetPassword} />
      <Header>
        {
          privateRoutes.map((route) => (
            <PrivateRoute
              key={route.path}
              {...route}
            />
          ))
        }
      </Header>
    </Switch>
  </BrowserRouter>
);

export default Routes;
