import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isAuthenticated } from '../configs/utils';
import { ROLE_SUPPORT } from '../constants/roles';

const PublicRoute = ({ component: Component, ...rest }) => {
  const { user } = useSelector((state) => state.me);

  const verifyAuth = (props) => {
    if (!isAuthenticated(user)) {
      return <Component {...props} />;
    }

    const redirectRoute = user.role_id === ROLE_SUPPORT ? 'monitoring' : 'restaurants';
    return <Redirect to={redirectRoute} />;
  };

  return <Route {...rest} render={(props) => verifyAuth(props)} />;
};

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PublicRoute;
