import Immutable from 'seamless-immutable';

export const Types = {
  GET_QUESTIONS_REQUEST: 'questions/GET_QUESTIONS_REQUEST',
  GET_QUESTIONS_SUCCESS: 'questions/GET_QUESTIONS_SUCCESS',
  GET_QUESTIONS_FAILED: 'questions/GET_QUESTIONS_FAILED',

  GET_QUESTION_REQUEST: 'questions/GET_QUESTION_REQUEST',
  GET_QUESTION_SUCCESS: 'questions/GET_QUESTION_SUCCESS',
  GET_QUESTION_FAILED: 'questions/GET_QUESTION_FAILED',

  CREATE_QUESTIONS_REQUEST: 'questions/CREATE_QUESTIONS_REQUEST',
  CREATE_QUESTIONS_SUCCESS: 'questions/CREATE_QUESTIONS_SUCCESS',
  CREATE_QUESTIONS_FAILED: 'questions/CREATE_QUESTIONS_FAILED',

  EDIT_QUESTION_REQUEST: 'questions/EDIT_QUESTION_REQUEST',
  EDIT_QUESTION_SUCCESS: 'questions/EDIT_QUESTION_SUCCESS',
  EDIT_QUESTION_FAILED: 'questions/EDIT_QUESTION_FAILED',

  DELETE_QUESTION_REQUEST: 'questions/DELETE_QUESTION_REQUEST',
  DELETE_QUESTION_SUCCESS: 'questions/DELETE_QUESTION_SUCCESS',
  DELETE_QUESTION_FAILED: 'questions/DELETE_QUESTION_FAILED',

  HANDLE_MODAL_VISIBILITY: 'questions/HANDLE_MODAL_VISIBILITY',
};

const INITIAL_STATE = Immutable({
  loading: false,
  saving: false,
  visible: false,
  data: [],
  question: null,
});

export default function questions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_QUESTIONS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_QUESTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }

    case Types.GET_QUESTIONS_FAILED: {
      return {
        ...state,
        loading: false,
        data: [],
      };
    }

    case Types.GET_QUESTION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_QUESTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        question: action.payload,
      };
    }

    case Types.GET_QUESTION_FAILED: {
      return {
        ...state,
        loading: false,
        question: {},
      };
    }

    case Types.CREATE_QUESTIONS_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.CREATE_QUESTIONS_SUCCESS: {
      return {
        ...state,
        saving: false,
        data: [...state.data, action.payload],
      };
    }

    case Types.CREATE_QUESTIONS_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.EDIT_QUESTION_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.EDIT_QUESTION_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.EDIT_QUESTION_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.DELETE_QUESTION_SUCCESS: {
      return {
        ...state,
        data: state.data.filter((question) => question.id !== action.payload),
      };
    }

    case Types.HANDLE_MODAL_VISIBILITY: {
      return {
        ...state,
        visible: action.payload,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getQuestions: (payload) => ({
    type: Types.GET_QUESTIONS_REQUEST,
    payload,
  }),

  getQuestionsSuccess: (payload) => ({
    type: Types.GET_QUESTIONS_SUCCESS,
    payload,
  }),

  getQuestionsFailed: (payload) => ({
    type: Types.GET_QUESTIONS_FAILED,
    payload,
  }),

  getQuestion: (payload) => ({
    type: Types.GET_QUESTION_REQUEST,
    payload,
  }),

  getQuestionSuccess: (payload) => ({
    type: Types.GET_QUESTION_SUCCESS,
    payload,
  }),

  getQuestionFailed: (payload) => ({
    type: Types.GET_QUESTION_FAILED,
    payload,
  }),

  createQuestion: (payload) => ({
    type: Types.CREATE_QUESTIONS_REQUEST,
    payload,
  }),

  createQuestionSuccess: (payload) => ({
    type: Types.CREATE_QUESTIONS_SUCCESS,
    payload,
  }),

  createQuestionFailed: (payload) => ({
    type: Types.CREATE_QUESTIONS_FAILED,
    payload,
  }),

  editQuestion: (payload) => ({
    type: Types.EDIT_QUESTION_REQUEST,
    payload,
  }),

  editQuestionSuccess: (payload) => ({
    type: Types.EDIT_QUESTION_SUCCESS,
    payload,
  }),

  editQuestionFailed: (payload) => ({
    type: Types.EDIT_QUESTION_FAILED,
    payload,
  }),

  deleteQuestion: (payload) => ({
    type: Types.DELETE_QUESTION_REQUEST,
    payload,
  }),

  deleteQuestionSuccess: (payload) => ({
    type: Types.DELETE_QUESTION_SUCCESS,
    payload,
  }),

  deleteQuestionFailed: (payload) => ({
    type: Types.DELETE_QUESTION_FAILED,
    payload,
  }),

  handleModalVisibility: (payload) => ({
    type: Types.HANDLE_MODAL_VISIBILITY,
    payload,
  }),
};
