import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
  CheckOutlined, 
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Card, Result, Table, Tag, Modal } from 'antd';

import { Wrapper, Content } from './styles';
import { restaurants } from '../columns';

import { Creators as RestaurantActions } from '../../../../store/ducks/restaurants';

import { ROLE_MASTER } from '../../../../constants/roles';
import Modules from '../../modules';

const { confirm } = Modal;

const NewRestaurants = () => {
  const dispatch = useDispatch();
  const { new: data, loading } = useSelector((state) => state.restaurants);
  const { user } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(RestaurantActions.getRestaurants());
  }, [dispatch]);

  const deleteRestaurant = (restaurant) => {
    confirm({
      content: `Você deseja realmente deletar o restaurante "${restaurant.name}"?`,
      icon: <ExclamationCircleOutlined />,
      title: 'Solicitação para deletar restaurante',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk() {
        dispatch(
          RestaurantActions.deleteRestaurant(restaurant),
        );
      },
    });
  };

  const columns = [
    ...restaurants,
    {
      title: 'Ações',
      key: 'actions',
      width: 140,
      render: (_, record) => (
        <div style={{ padding: 8 }}>
          {[3, 100].indexOf(user.role_id) !== -1 && (
            <Tag
              style={{ borderRadius: 10, fontWeight: 500, cursor: 'pointer' }}
              color="red"
              onClick={() => deleteRestaurant(record)}
            >
              Remover &nbsp;
              <DeleteOutlined style={{ fontSize: 13 }} />
            </Tag>
          )}

          {user.role_id === ROLE_MASTER && (
            <Tag
              style={{ borderRadius: 10, fontWeight: 500, cursor: 'pointer' }}
              color="green"
              onClick={() => dispatch(RestaurantActions.confirmRestaurant(record))}
            >
              Confirmar &nbsp;
              <CheckOutlined style={{ fontSize: 13 }} />
            </Tag>
          )}
        </div>
      ),
    },
  ];

  const renderNewRestaurants = () => {
    if (!data || data.length === 0) {
      return (
        <Result
          status="404"
          title="Ops! Nenhum restaurante novo..."
          subTitle="Desculpe! A lista está vazia."
        />
      );
    }

    return (
      <Table
        columns={columns}
        loading={loading}
        locale={{
          emptyText: 'Nenhum restaurante encontrado',
        }}
        className="order-table"
        expandable={{ expandedRowRender: Modules, rowExpandable: (restaurant) => restaurant.documents.length }}
        rowKey={(restaurant) => restaurant.id}
        dataSource={data}
        scroll={{ y: '60vh' }}
      />
    );
  };

  return (
    <Wrapper span={24}>
      <Card style={{ borderRadius: 5 }} title="Novos Cadastros">
        <Content>{renderNewRestaurants()}</Content>
      </Card>
    </Wrapper>
  );
};

export default NewRestaurants;
