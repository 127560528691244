import React from 'react';
import { InputNumber } from 'antd';
import PropTypes from 'prop-types';

const InputGroup = (props) => {
  const { prefix, ...rest } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          backgroundColor: '#fafafa',
          height: '100%',
          width: 40,
          border: '1px solid #d9d9d9',
          padding: '4px 8px',
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
          borderRight: 'none',
        }}
      >
        {prefix}
      </span>
      <InputNumber
        min={0}
        precision={2}
        prefix={prefix}
        decimalSeparator=","
        parser={(value) => value.replace(`${prefix} `, '')}
        {...rest}
      />
    </div>
  );
};

InputGroup.propTypes = {
  prefix: PropTypes.string,
};

InputGroup.defaultProps = {
  prefix: 'R$',
};

export default InputGroup;
