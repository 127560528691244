import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Card, Button } from 'antd';
import {
  PlusCircleOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import { Creators as QuestionCreators } from '../../store/ducks/questions';
import { Creators as ReasonCreators } from '../../store/ducks/reasons';

import { Wrapper, Content } from './styles';
import List from './components/List';
import Form from './components/Form';
import Reason from '../../components/Reason';

const Question = () => {
  const dispatch = useDispatch();

  const [editableQuestion, setEditableQuestion] = useState(null);

  const {
    visible,
    loading,
    data,
    saving,
  } = useSelector((state) => state.questions);

  const {
    reasons,
  } = useSelector((state) => state.reasons);

  const handleModalStatus = (status) => {
    dispatch(QuestionCreators.handleModalVisibility(status));
  };

  useEffect(() => {
    dispatch(QuestionCreators.getQuestions());
  }, [dispatch]);

  return (
    <Wrapper>
      <Form
        visible={visible}
        id={editableQuestion}
        saving={saving}
        reasons={reasons}
        handleSave={(values) => dispatch(QuestionCreators.createQuestion(values))}
        handleEdit={(values) => {
          setEditableQuestion(null);
          dispatch(QuestionCreators.editQuestion(values));
        }}
        handleModal={handleModalStatus}
      />

      <Reason />

      <Row justify="space-between">
        <Content xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            style={{ borderRadius: 5 }}
            title="Perguntas"
            extra={
              <div>
                <Button
                  onClick={() => {
                    dispatch(ReasonCreators.handleModalVisibility(true));
                  }}
                  style={{
                    borderRadius: 20,
                    marginRight: '10px'
                  }}
                  type="secondary"
                  icon={<UnorderedListOutlined />}
                >
                  Motivos
                </Button>
                <Button
                  onClick={() => {
                    setEditableQuestion(null);
                    handleModalStatus(true);
                  }}
                  style={{ borderRadius: 20 }}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Adicionar Pergunta
                </Button>
              </div>
            }
          >
            <List
              handleEditQuestion={setEditableQuestion}
              loading={loading}
              questions={data}
              handleDelete={(question) =>
                dispatch(QuestionCreators.deleteQuestion(question))
              }
              handleModal={handleModalStatus}
            />
          </Card>
        </Content>
      </Row>
    </Wrapper>
  );
};

export default Question;
