import Immutable from 'seamless-immutable';

export const Types = {
  GET_RESTAURANT_IMAGE_REQUEST: 'restaurant/GET_RESTAURANT_IMAGE_REQUEST',
  GET_RESTAURANT_IMAGE_SUCCESS: 'restaurant/GET_RESTAURANT_IMAGE_SUCCESS',
  GET_RESTAURANT_IMAGE_FAILED: 'restaurant/GET_RESTAURANT_IMAGE_FAILED',

  GET_RESTAURANT_REQUEST: 'restaurant/GET_RESTAURANT_REQUEST',
  GET_RESTAURANT_SUCCESS: 'restaurant/GET_RESTAURANT_SUCCESS',
  GET_RESTAURANT_FAILED: 'restaurant/GET_RESTAURANT_FAILED',

  UPDATE_RESTAURANT_REQUEST: 'restaurant/UPDATE_RESTAURANT_REQUEST',
  UPDATE_RESTAURANT_SUCCESS: 'restaurant/UPDATE_RESTAURANT_SUCCESS',
  UPDATE_RESTAURANT_FAILED: 'restaurant/UPDATE_RESTAURANT_FAILED',

  UPLOAD_IMAGE_REQUEST: 'restaurant/UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS: 'restaurant/UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILED: 'restaurant/UPLOAD_IMAGE_FAILED',

  UPLOAD_DOCUMENT_REQUEST: 'restaurant/UPLOAD_DOCUMENT_REQUEST',
  UPLOAD_DOCUMENT_SUCCESS: 'restaurant/UPLOAD_DOCUMENT_SUCCESS',
  UPLOAD_DOCUMENT_FAILED: 'restaurant/UPLOAD_DOCUMENT_FAILED',

  GET_RESTAURANTS_REQUEST: 'restaurants/GET_RESTAURANTS_REQUEST',
  GET_RESTAURANTS_SUCCESS: 'restaurants/GET_RESTAURANTS_SUCCESS',
  GET_RESTAURANTS_FAILED: 'restaurants/GET_RESTAURANTS_FAILED',

  GET_SIMPLE_LIST_REQUEST: 'restaurants/GET_SIMPLE_LIST_REQUEST',
  GET_SIMPLE_LIST_FINISHED: 'restaurants/GET_SIMPLE_LIST_FINISHED',

  TOGGLE_ACTIVE_REQUEST: 'restaurants/TOGGLE_ACTIVE_REQUEST',
  TOGGLE_ACTIVE_SUCCESS: 'restaurants/TOGGLE_ACTIVE_SUCCESS',
  TOGGLE_ACTIVE_FAILED: 'restaurants/TOGGLE_ACTIVE_FAILED',

  CHANGE_STATUS_REQUEST: 'restaurants/CHANGE_STATUS_REQUEST',
  CHANGE_STATUS_SUCCESS: 'restaurants/CHANGE_STATUS_SUCCESS',
  CHANGE_STATUS_FAILED: 'restaurants/CHANGE_STATUS_FAILED',

  CONFIRM_RESTAURANT_REQUEST: 'restaurants/CONFIRM_RESTAURANT_REQUEST',
  CONFIRM_RESTAURANT_SUCCESS: 'restaurants/CONFIRM_RESTAURANT_SUCCESS',
  CONFIRM_RESTAURANT_FAILED: 'restaurants/CONFIRM_RESTAURANT_FAILED',

  DELETE_RESTAURANT_REQUEST: 'restaurants/DELETE_RESTAURANT_REQUEST',
  DELETE_RESTAURANT_SUCCESS: 'restaurants/DELETE_RESTAURANT_SUCCESS',
  DELETE_RESTAURANT_FAILED: 'restaurants/DELETE_RESTAURANT_FAILED',
};

const INITIAL_STATE = Immutable({
  loading: false,
  saving: false,
  uploading: false,
  logo: null,
  data: [],
  new: [],
  enabled: [],
});

export default function restaurants(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_RESTAURANT_IMAGE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_RESTAURANT_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        logo: action.payload.logo,
      };
    }

    case Types.GET_RESTAURANT_IMAGE_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.GET_SIMPLE_LIST_FINISHED: {
      return {
        ...state,
        list: action.payload,
      };
    }

    case Types.GET_RESTAURANT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_RESTAURANT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload.restaurant,
          ...action.payload.restaurant.delivery,
          id: action.payload.restaurant.id,
        },
        logo: action.payload.logo,
      };
    }

    case Types.UPDATE_RESTAURANT_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.UPDATE_RESTAURANT_SUCCESS: {
      return {
        ...state,
        saving: false,
        data: {
          ...action.payload,
          ...action.payload.delivery,
          id: action.payload.id,
        },
      };
    }

    case Types.UPDATE_RESTAURANT_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.UPLOAD_IMAGE_REQUEST: {
      return {
        ...state,
        uploading: true,
      };
    }

    case Types.UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        uploading: false,
        logo: action.payload,
        data: {
          ...state.data,
        },
      };
    }

    case Types.UPLOAD_IMAGE_FAILED: {
      return {
        ...state,
        uploading: false,
      };
    }

    case Types.UPLOAD_DOCUMENT_REQUEST: {
      return {
        ...state,
        uploading: true,
      };
    }

    case Types.UPLOAD_DOCUMENT_SUCCESS: {
      return {
        ...state,
        uploading: false,
        logo: action.payload,
        data: {
          ...state.data,
        },
      };
    }

    case Types.UPLOAD_DOCUMENT_FAILED: {
      return {
        ...state,
        uploading: false,
      };
    }

    case Types.GET_RESTAURANTS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.GET_RESTAURANTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        new: action.payload.filter((restaurant) => !restaurant.active),
        enabled: action.payload.filter((restaurant) => !!restaurant.active),
      };
    }

    case Types.CHANGE_STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.CHANGE_STATUS_SUCCESS: {
      state.data.find((x) => x.id === action.payload.id).active_backoffice = action.payload.active_backoffice;

      return {
        ...state,
        loading: false,
        new: state.data.filter((restaurant) => !restaurant.active),
        enabled: state.data.filter((restaurant) => !!restaurant.active),
      };
    }

    case Types.CHANGE_STATUS_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.TOGGLE_ACTIVE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.TOGGLE_ACTIVE_SUCCESS: {
      state.data.find((x) => x.id === action.payload.id).active_backoffice = action.payload.active_backoffice;

      return {
        ...state,
        loading: false,
        new: state.data.filter((restaurant) => !restaurant.active),
        enabled: state.data.filter((restaurant) => !!restaurant.active),
      };
    }

    case Types.TOGGLE_ACTIVE_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.CONFIRM_RESTAURANT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.DELETE_RESTAURANT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.DELETE_RESTAURANT_SUCCESS: {
      return {
        ...state,
        loading: false,
        new: state.data.filter((restaurant) => restaurant.id !== action.payload && !restaurant.active),
        enabled: state.data.filter((restaurant) => restaurant.id !== action.payload && !!restaurant.active),
      };
    }

    case Types.DELETE_RESTAURANT_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.CONFIRM_RESTAURANT_SUCCESS: {
      return {
        ...state,
        loading: false,
        new: state.new.filter((restaurant) => restaurant.id !== action.payload.id),
        enabled: [...state.enabled, action.payload],
      };
    }

    case Types.CONFIRM_RESTAURANT_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getRestaurant: () => ({
    type: Types.GET_RESTAURANT_REQUEST,
  }),
  getRestaurantSuccess: (payload) => ({
    type: Types.GET_RESTAURANT_SUCCESS,
    payload,
  }),
  getRestaurantFailed: () => ({
    type: Types.GET_RESTAURANT_FAILED,
  }),

  updateRestaurant: (payload) => ({
    type: Types.UPDATE_RESTAURANT_REQUEST,
    payload,
  }),
  updateRestaurantSuccess: (payload) => ({
    type: Types.UPDATE_RESTAURANT_SUCCESS,
    payload,
  }),
  updateRestaurantFailed: () => ({
    type: Types.UPDATE_RESTAURANT_FAILED,
  }),

  uploadImage: (payload) => ({
    type: Types.UPLOAD_IMAGE_REQUEST,
    payload,
  }),
  uploadImageSuccess: (payload) => ({
    type: Types.UPLOAD_IMAGE_SUCCESS,
    payload,
  }),
  uploadImageFailed: () => ({
    type: Types.UPLOAD_IMAGE_FAILED,
  }),

  uploadDocument: (payload) => ({
    type: Types.UPLOAD_DOCUMENT_REQUEST,
    payload,
  }),
  uploadDocumentSuccess: (payload) => ({
    type: Types.UPLOAD_DOCUMENT_SUCCESS,
    payload,
  }),
  uploadDocumentFailed: () => ({
    type: Types.UPLOAD_DOCUMENT_FAILED,
  }),

  getRestaurantImage: (payload) => ({
    type: Types.GET_RESTAURANT_IMAGE_REQUEST,
    payload,
  }),

  getRestaurantImageSuccess: (payload) => ({
    type: Types.GET_RESTAURANT_IMAGE_SUCCESS,
    payload,
  }),

  getRestaurantImageFailed: (payload) => ({
    type: Types.GET_RESTAURANT_IMAGE_FAILED,
    payload,
  }),
  getRestaurants: () => ({
    type: Types.GET_RESTAURANTS_REQUEST,
  }),
  getRestaurantsSuccess: (payload) => ({
    type: Types.GET_RESTAURANTS_SUCCESS,
    payload,
  }),
  getRestaurantsFailed: (payload) => ({
    type: Types.GET_RESTAURANTS_FAILED,
    payload,
  }),
  changeStatus: (payload) => ({
    type: Types.CHANGE_STATUS_REQUEST,
    payload,
  }),
  changeStatusSuccess: (payload) => ({
    type: Types.CHANGE_STATUS_SUCCESS,
    payload,
  }),
  changeStatusFailed: (payload) => ({
    type: Types.CHANGE_STATUS_FAILED,
    payload,
  }),
  toogleActive: (payload) => ({
    type: Types.TOGGLE_ACTIVE_REQUEST,
    payload,
  }),
  toggleActiveSuccess: (payload) => ({
    type: Types.TOGGLE_ACTIVE_SUCCESS,
    payload,
  }),
  toggleActiveFailed: (payload) => ({
    type: Types.TOGGLE_ACTIVE_FAILED,
    payload,
  }),
  deleteRestaurant: (payload) => ({
    type: Types.DELETE_RESTAURANT_REQUEST,
    payload,
  }),
  deleteRestaurantSuccess: (payload) => ({
    type: Types.DELETE_RESTAURANT_SUCCESS,
    payload,
  }),
  deleteRestaurantFailed: (payload) => ({
    type: Types.DELETE_RESTAURANT_FAILED,
    payload,
  }),
  confirmRestaurant: (payload) => ({
    type: Types.CONFIRM_RESTAURANT_REQUEST,
    payload,
  }),
  confirmRestaurantSuccess: (payload) => ({
    type: Types.CONFIRM_RESTAURANT_SUCCESS,
    payload,
  }),
  confirmRestaurantFailed: (payload) => ({
    type: Types.CONFIRM_RESTAURANT_FAILED,
    payload,
  }),
  getSimpleList: () => ({
    type: Types.GET_SIMPLE_LIST_REQUEST,
  }),
  getSimpleListFinished: (payload) => ({
    type: Types.GET_SIMPLE_LIST_FINISHED,
    payload,
  }),
};
