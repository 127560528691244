import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import api from '../../services/api';
import { Creators as QuestionActions } from '../ducks/questions';

const Constants = {
  DELETE_QUESTION: 'question/delete',
};

export function* getQuestions() {
  try {
    const { data, status } = yield call(
      api.get,
      `/questions`,
    );
    if (status === 200) {
      yield put(QuestionActions.getQuestionsSuccess(data.data));
    }
  } catch (error) {
    yield put(QuestionActions.getQuestionsFailed(error.message));
  }
}

export function* createQuestion({ payload }) {
  try {
    const { data, status } = yield call(api.post, '/questions', payload);

    if (status === 201) {
      message.success(`A pergunta '${payload.title}' foi criada com sucesso!`);

      yield put(QuestionActions.createQuestionSuccess(data));
      yield put(QuestionActions.getQuestions());
      yield put(QuestionActions.handleModalVisibility(false));
    }
  } catch (error) {
    message.error('Houve um erro ao criar a pergunta, tente novamente mais tarde!');
    yield put(QuestionActions.createQuestionFailed());
  }
}

export function* editQuestion({ payload }) {
  try {
    const { data, status } = yield call(api.put, `/questions/${payload.id}`, payload);

    if (status === 204) {
      message.success('A pergunta foi alterada com sucesso!');
      yield put(QuestionActions.editQuestionSuccess(data));
      yield put(QuestionActions.handleModalVisibility(false));
      yield put(QuestionActions.getQuestions());
    }
  } catch (error) {
    message.error('Houve um erro ao editar a pergunta, tente novamente mais tarde!');
    yield put(QuestionActions.editQuestionFailed());
  }
}

export function* deleteQuestion({ payload }) {
  message.loading({
    content: 'Estamos processando a sua solicitação de exclusão!',
    key: Constants.DELETE_QUESTION,
  });
  try {
    const { status } = yield call(api.delete, `/questions/${payload.id}`);

    if (status === 204) {
      message.success({
        content: `A pergunta "${payload.title}" foi excluída com sucesso!`,
        key: Constants.DELETE_QUESTION,
      });

      yield put(QuestionActions.deleteQuestionSuccess(payload.id));
      yield put(QuestionActions.getQuestions());
    }
  } catch (error) {
    message.error('Houve um erro ao excluir a pergunta, tente novamente mais tarde!');
    yield put(QuestionActions.deleteQuestionFailed());
  }
}

export function* getQuestion({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      `/questions/${payload.id}`,
    );

    if (status === 200) {
      yield put(QuestionActions.getQuestionSuccess(data));
    }
  } catch (error) {
    message.error('Houve um erro ao buscar a pergunta, tente novamente mais tarde!');

    yield put(QuestionActions.getQuestionFailed());
  }
}