import styled from 'styled-components';
import { Layout, Tabs } from 'antd';

export const Wrapper = styled(Layout)`
  flex: 1;
  overflow: auto;
  height: calc(100vh - 24px - 40px - 40px);
`;

export const TabPainel = styled(Tabs)`
  .ant-tabs-nav-list {
    margin: 10px 0 0 30px;
  }

  .ant-tabs-bar {
    padding-top: 20px;
    padding-left: 30px;
  }
`;
