import Immutable from 'seamless-immutable';

export const Types = {
  GET_CUSTOMERS_REQUEST: 'customers/GET_CUSTOMERS_REQUEST',
  GET_CUSTOMERS_SUCCESS: 'customers/GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILED: 'customers/GET_CUSTOMERS_FAILED',

  CREATE_CUSTOMERS_REQUEST: 'customers/CREATE_CUSTOMERS_REQUEST',
  CREATE_CUSTOMERS_SUCCESS: 'customers/CREATE_CUSTOMERS_SUCCESS',
  CREATE_CUSTOMERS_FAILED: 'customers/CREATE_CUSTOMERS_FAILED',

  EDIT_CUSTOMER_REQUEST: 'customers/EDIT_CUSTOMER_REQUEST',
  EDIT_CUSTOMER_SUCCESS: 'customers/EDIT_CUSTOMER_SUCCESS',
  EDIT_CUSTOMER_FAILED: 'customers/EDIT_CUSTOMER_FAILED',

  TOGGLE_CUSTOMER_VISIBILITY_REQUEST: 'customers/TOGGLE_CUSTOMER_VISIBILITY_REQUEST',
  TOGGLE_CUSTOMER_VISIBILITY_SUCCESS: 'customers/TOGGLE_CUSTOMER_VISIBILITY_SUCCESS',
  TOGGLE_CUSTOMER_VISIBILITY_FAILED: 'customers/TOGGLE_CUSTOMER_VISIBILITY_FAILED',

  DELETE_CUSTOMER_REQUEST: 'customers/DELETE_CUSTOMER_REQUEST',
  DELETE_CUSTOMER_SUCCESS: 'customers/DELETE_CUSTOMER_SUCCESS',
  DELETE_CUSTOMER_FAILED: 'customers/DELETE_CUSTOMER_FAILED',

  UPLOAD_IMAGE_REQUEST: 'customers/UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS: 'customers/UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILED: 'customers/UPLOAD_IMAGE_FAILED',

  HANDLE_MODAL_VISIBILITY: 'customers/HANDLE_MODAL_VISIBILITY',
};

const INITIAL_STATE = Immutable({
  loading: false,
  uploading: null,
  saving: false,
  visible: false,
  data: [],
});

export default function customers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CUSTOMERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.GET_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }

    case Types.GET_CUSTOMERS_FAILED: {
      return {
        ...state,
        loading: false,
        data: [],
      };
    }

    case Types.EDIT_CUSTOMER_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }
    case Types.EDIT_CUSTOMER_SUCCESS: {
      return {
        ...state,
        saving: false,
        data: state.data.map((coupon) => {
          if (coupon.id === action.payload.id) {
            return {
              ...coupon,
              name: action.payload.name,
            };
          }

          return coupon;
        }),
      };
    }
    case Types.EDIT_CUSTOMER_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.HANDLE_MODAL_VISIBILITY: {
      return {
        ...state,
        visible: action.payload,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getCustomers: (payload) => ({
    type: Types.GET_CUSTOMERS_REQUEST,
    payload,
  }),
  getCustomersSuccess: (payload) => ({
    type: Types.GET_CUSTOMERS_SUCCESS,
    payload,
  }),
  getCustomersFailed: (payload) => ({
    type: Types.GET_CUSTOMERS_FAILED,
    payload,
  }),
  editCustomer: (payload) => ({
    type: Types.EDIT_CUSTOMER_REQUEST,
    payload,
  }),
  editCustomerSuccess: (payload) => ({
    type: Types.EDIT_CUSTOMER_SUCCESS,
    payload,
  }),
  editCustomerFailed: (payload) => ({
    type: Types.EDIT_CUSTOMER_FAILED,
    payload,
  }),
  toggleCustomerVisibility: (payload) => ({
    type: Types.TOGGLE_CUSTOMER_VISIBILITY_REQUEST,
    payload,
  }),
  toggleCustomerVisibilitySuccess: (payload) => ({
    type: Types.TOGGLE_CUSTOMER_VISIBILITY_SUCCESS,
    payload,
  }),
  toggleCustomerVisibilityFailed: (payload) => ({
    type: Types.TOGGLE_CUSTOMER_VISIBILITY_FAILED,
    payload,
  }),
  handleModalVisibility: (payload) => ({
    type: Types.HANDLE_MODAL_VISIBILITY,
    payload,
  }),
};
