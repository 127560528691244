import { call, put } from 'redux-saga/effects';
import api from '../../services/api';
import { Creators as OrderActions } from '../ducks/orders';

export function* getOrders() {
  try {
    const { data, status } = yield call(api.get, '/orders');

    if (status === 200) {
      yield put(OrderActions.getOrdersSuccess(data.data));
    }
  } catch (error) {
    yield put(OrderActions.getOrdersFailed(error.message));
  }
}

export function* changeStatus({ payload: order }) {
  try {
    const dataOrder = {
      status_id: order.new_order_status,
    };

    if (order.delivery_type) {
      dataOrder.delivery_type = order.delivery_type;
    }

    const { data, status } = yield call(api.put, `/orders/${order.record.id}/status`, dataOrder);

    if (status === 200) {
      yield put(OrderActions.changeStatusSuccess(data.data));
    }
  } catch (error) {
    yield put(OrderActions.changeStatusFailed(error.message));
  }
}
