import Immutable from 'seamless-immutable';

export const Types = {
  GET_REASONS: 'reasons/GET_REASONS',
  CREATE_REASON: 'reasons/CREATE_REASON',
  EDIT_REASON: 'reasons/EDIT_REASON',
  DELETE_REASON: 'reasons/DELETE_REASON',

  SET_REASONS: 'reasons/SET_REASON',
  SET_SAVING: 'reasons/SET_SAVING',
  SET_VISIBLE: 'reasons/SET_VISIBLE',
  SET_LOADING: 'reasons/SET_LOADING',

  HANDLE_MODAL_VISIBILITY: 'reasons/HANDLE_MODAL_VISIBILITY',
  HANDLE_FORM_MODAL_VISIBILITY: 'reasons/HANDLE_FORM_MODAL_VISIBILITY',
};

const INITIAL_STATE = Immutable({
  loading: false,
  saving: false,
  visible: false,
  formVisible: false,
  reasons: [],
});

export default function questions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_REASONS: {
      return {
        ...state,
        reasons: action.payload,
      };
    }

    case Types.SET_SAVING: {
      return {
        ...state,
        saving: action.payload,
      };
    }

    case Types.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case Types.HANDLE_MODAL_VISIBILITY: {
      return {
        ...state,
        visible: action.payload,
      };
    }

    case Types.HANDLE_FORM_MODAL_VISIBILITY: {
      return {
        ...state,
        formVisible: action.payload,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getReasons: () => ({
    type: Types.GET_REASONS,
  }),

  setReasons: (payload) => ({
    type: Types.SET_REASONS,
    payload,
  }),

  createReason: (payload) => ({
    type: Types.CREATE_REASON,
    payload,
  }),

  editReason: (payload) => ({
    type: Types.EDIT_REASON,
    payload,
  }),

  deleteReason: (payload) => ({
    type: Types.DELETE_REASON,
    payload,
  }),

  setLoading: (payload) => ({
    type: Types.SET_LOADING,
    payload,
  }),

  setSaving: (payload) => ({
    type: Types.SET_SAVING,
    payload,
  }),

  handleModalVisibility: (payload) => ({
    type: Types.HANDLE_MODAL_VISIBILITY,
    payload,
  }),

  handleFormModalVisibility: (payload) => ({
    type: Types.HANDLE_FORM_MODAL_VISIBILITY,
    payload,
  }),
};
