import Immutable from 'seamless-immutable';

export const Types = {
  PLACE_DELIVERY_ORDER_REQUEST: 'delivery/PLACE_DELIVERY_ORDER_REQUEST',
  PLACE_DELIVERY_ORDER_SUCCESS: 'delivery/PLACE_DELIVERY_ORDER_SUCCESS',
  PLACE_DELIVERY_ORDER_FAILED: 'delivery/PLACE_DELIVERY_ORDER_FAILED',
  RESET_PLACE_DELIVERY_ORDER: 'delivery/RESET_PLACE_DELIVERY_ORDER',

  CANCEL_DELIVERY_ORDER_REQUEST: 'delivery/CANCEL_DELIVERY_ORDER_REQUEST',
  CANCEL_DELIVERY_ORDER_SUCCESS: 'delivery/CANCEL_DELIVERY_ORDER_SUCCESS',
  CANCEL_DELIVERY_ORDER_FAILED: 'delivery/CANCEL_DELIVERY_ORDER_FAILED',

  HANDLE_GEOLOCATION_VISIBILITY: 'delivery/HANDLE_GEOLOCATION_VISIBILITY',

  GEO_LOCATION_REQUEST: 'delivery/GEO_LOCATION_REQUEST',
  SET_GEO_LOCATION: 'delivery/SET_GEO_LOCATION',
  RESET_GEO_LOCATION: 'delivery/RESET_GEO_LOCATION',
  SET_MODAL_GEO_LOCATION_CLOSED: 'delivery/SET_MODAL_GEO_LOCATION_CLOSED',

  ADD_QUOTE_SPINNER: 'delivery/ADD_QUOTE_SPINNER',
  REMOVE_QUOTE_SPINNER: 'delivery/REMOVE_QUOTE_SPINNER',
};

const INITIAL_STATE = Immutable({
  visibleGeoLocation: false,
  modalGeoLocationClosed: true,
  placeDeliveryOrder: null,
  cancelDeliveryOrder: [],
  geoLocation: {
    latitude: null,
    longitude: null,
  },
  geoLocationOrder: null,
  deliverySpinners: [],
});

export default function delivery(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.PLACE_DELIVERY_ORDER_REQUEST: {
      return {
        ...state,
      };
    }

    case Types.PLACE_DELIVERY_ORDER_SUCCESS: {
      return {
        ...state,
        placeDeliveryOrder: action.payload,
      };
    }

    case Types.PLACE_DELIVERY_ORDER_FAILED: {
      return {
        ...state,
        placeDeliveryOrder: {
          success: false,
        },
      };
    }

    case Types.RESET_PLACE_DELIVERY_ORDER: {
      return {
        ...state,
        placeDeliveryOrder: null,
      };
    }

    case Types.CANCEL_DELIVERY_ORDER_REQUEST: {
      return {
        ...state,
      };
    }

    case Types.CANCEL_DELIVERY_ORDER_SUCCESS: {
      return {
        ...state,
        cancelDeliveryOrder: action.payload,
      };
    }

    case Types.CANCEL_DELIVERY_ORDER_FAILED: {
      return {
        ...state,
        cancelDeliveryOrder: action.payload,
      };
    }

    case Types.HANDLE_GEOLOCATION_VISIBILITY: {
      return {
        ...state,
        visibleGeoLocation: action.payload,
      };
    }

    case Types.GEO_LOCATION_REQUEST: {
      return {
        ...state,
        geoLocationOrder: action.payload,
      };
    }

    case Types.SET_GEO_LOCATION: {
      return {
        ...state,
        geoLocation: action.payload,
      };
    }

    case Types.RESET_GEO_LOCATION: {
      return {
        ...state,
        geoLocation: {
          latitude: null,
          longitude: null,
        },
      };
    }

    case Types.SET_MODAL_GEO_LOCATION_CLOSED: {
      return {
        ...state,
        modalGeoLocationClosed: action.payload,
      };
    }

    case Types.ADD_QUOTE_SPINNER: {
      return {
        ...state,
        deliverySpinners: [...state.deliverySpinners, action.payload],
      };
    }

    case Types.REMOVE_QUOTE_SPINNER: {
      return {
        ...state,
        deliverySpinners: state.deliverySpinners.filter((value) => value !== action.payload),
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  placeDeliveryOrder: (payload) => ({
    type: Types.PLACE_DELIVERY_ORDER_REQUEST,
    payload,
  }),

  placeDeliveryOrderSuccess: (payload) => ({
    type: Types.PLACE_DELIVERY_ORDER_SUCCESS,
    payload,
  }),

  placeDeliveryOrderFailed: (payload) => ({
    type: Types.PLACE_DELIVERY_ORDER_FAILED,
    payload,
  }),

  resetPlaceDeliveryOrder: () => ({
    type: Types.RESET_PLACE_DELIVERY_ORDER,
  }),

  cancelDeliveryOrder: (payload) => ({
    type: Types.CANCEL_DELIVERY_ORDER_REQUEST,
    payload,
  }),

  cancelDeliveryOrderSuccess: (payload) => ({
    type: Types.CANCEL_DELIVERY_ORDER_SUCCESS,
    payload,
  }),

  cancelDeliveryOrderFailed: (payload) => ({
    type: Types.CANCEL_DELIVERY_ORDER_FAILED,
    payload,
  }),

  geoLocation: (payload) => ({
    type: Types.GEO_LOCATION_REQUEST,
    payload,
  }),

  setGeoLocation: (payload) => ({
    type: Types.SET_GEO_LOCATION,
    payload,
  }),

  resetGeoLocation: () => ({
    type: Types.RESET_GEO_LOCATION,
  }),

  handleGeolocationVisibility: (payload) => ({
    type: Types.HANDLE_GEOLOCATION_VISIBILITY,
    payload,
  }),

  setModalGeoLocationClosed: (payload) => ({
    type: Types.SET_MODAL_GEO_LOCATION_CLOSED,
    payload,
  }),

  addQuoteSpinner: (payload) => ({
    type: Types.ADD_QUOTE_SPINNER,
    payload,
  }),

  removeQuoteSpinner: (payload) => ({
    type: Types.REMOVE_QUOTE_SPINNER,
    payload,
  }),
};
