import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL_BASE,
});

api.defaults.headers = {
  'content-type': 'application/json',
  Accept: 'application/json',
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 401) {
      localStorage.clear();
      return (window.location.href = '/login');
    }
  },
);

api.interceptors.request.use(async (config) => {
  const token = await localStorage.getItem('access_token');

  config.headers.Authorization = `Bearer ${token || ''}`;

  return config;
});

export default api;
