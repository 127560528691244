import React from 'react';

import { getPrice, formatDocument } from '../../configs/utils';

import './index.css';

class OrderPrinting extends React.Component {
  render() {
    const order = this.props.order;
    const items = this.props.order.items;
    const restaurant = this.props.order.restaurant;

    const header = (
      <>
        <table className="no-padding">
          <tbody>
            <tr>
              <td>
                <center>
                  <h2><b>GET IN DELIVERY</b></h2>
                </center>
              </td>
            </tr>
            <tr>
              <td><center>{restaurant.name}</center></td>
            </tr>
            <tr>
              <td>
                <center>Tel.: { restaurant.phone }</center>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );

    const dataOrder = (
      <table className="no-padding">
        <tbody>
          <tr>
            <td colSpan={2}>
              Consumidor: {order.customer.name}
            </td>
          </tr>
          <tr>
            <td>{order.date_order}</td>
            <td align="right">
              <h3><b>N°: {order.id.toString().padStart(6,'0')}</b></h3>
            </td>
          </tr>
        </tbody>
      </table>
    );

    const listOptions = (options) => options.map((option, index) => (
      <React.Fragment key={ index }>
        <tr>
          <td colSpan={2}>{option.group} - {option.quantity > 1 ? option.quantity : ''} {option.title}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td align="right">{ option.quantity } X { getPrice(option.price) }</td>
          <td align="right">{ getPrice(option.quantity * option.price) }</td>
        </tr>
      </React.Fragment>
    ));

    const listItems = items.map((item, index) => (
      <tbody key={index}>
        <tr>
          <td colSpan={2}>{ item.title }</td>
        </tr>

        <tr>
          <td>&nbsp;</td>
          <td align="right">{ item.quantity } X { getPrice(item.price) }</td>
          <td align="right">{ getPrice(item.total) }</td>
        </tr>

        { listOptions(item.options) }

        <tr style={{ borderBottom: "1px dotted gray" }}>
          <td colSpan={3}>
            {
              item.notes && item.notes.length > 0
                ? `OBS: ${item.notes}` : item.notes
            }
          </td>
        </tr>
      </tbody>
    ));

    const productTable = (
      <table className="no-padding">
        <thead
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <tr>
            <th colSpan={2}>DESCRIÇÃO</th>
            <th><center>VALOR (R$)</center></th>
          </tr>
        </thead>
        { listItems }
      </table>
    );

    const totalization = (
      <table className="no-padding">
        <tbody
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <tr>
            <td><b>Total dos itens:</b></td>
            <td align="right"><b>{ getPrice(order.item_total) }</b></td>
          </tr>
          <tr style={{ fontWeight: "bold" }}>
            <td>Total do desconto:</td>
            <td align="right">{ getPrice(order.discount_total) }</td>
          </tr>
          <tr style={{ fontWeight: "bold" }}>
            <td>Total do pedido:</td>
            <td align="right">{ getPrice(order.total) }</td>
          </tr>
        </tbody>
      </table>
    );

    const info = (
      <>
        <tr>
          <td><h3><strong>Informações para entrega</strong></h3></td>
        </tr>
        <tr>
          <td>Contato: {order.customer.name}</td>
        </tr>
        <tr>
          <td>End.: {order.delivery_address.street}, {order.delivery_address.number}</td>
        </tr>
        <tr>
          <td>Complemento: {order.delivery_address.complement}</td>
        </tr>
        <tr>
          <td>Bairro: {order.delivery_address.neighborhood} - CEP: {order.delivery_address.zipcode}</td>
        </tr>
        <tr>
          <td>Cidade: {order.delivery_address.city} / {order.delivery_address.state}</td>
        </tr>
      </>
    );

    const billing = (
      <>
        <tr>
          <td><h3><strong>Informações para faturamento</strong></h3></td>
        </tr>
        <tr>
          <td>
            CPF:
            {' '}
            {formatDocument(order.invoice_document)}
          </td>
        </tr>
      </>
    );

    return (
      <table className="print-table">
        <tbody>
          <tr>
            <td><center>{header}</center></td>
          </tr>

          <tr
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <td><center>SEM VALOR FISCAL</center></td>
          </tr>

          <tr>
            <td>{ dataOrder }</td>
          </tr>

          <tr>
            <td>{ productTable }</td>
          </tr>

          <tr>
            <td>
              { totalization }
            </td>
          </tr>

          <tr><td>&nbsp;</td></tr>

          { info }

          <tr><td>&nbsp;</td></tr>

          {(order.invoice_document
            && order.invoice_document.trim().length > 0) ? billing : <></>}
        </tbody>
      </table>
    );
  }
}

export default OrderPrinting;
