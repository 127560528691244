import firebase from '../services/firebase';

const db = firebase.collection('sessions');

const splitIds = (ids, response) => {
  if (ids.length > 10) {
    const newIds = ids.slice(0, 10);
    response.push(newIds);
    return splitIds(ids.slice(10), response);
  }

  response.push(ids);
};

export const findRestaurantSessions = (restaurants) => {
  const idGroups = [];
  splitIds(restaurants, idGroups);

  const promises = idGroups.map((ids) => {
    const resources = db.where('restaurant_id', 'in', ids)
      .orderBy('signed_in_at', 'desc');

    return new Promise((resolve) => {
      resources.get().then((users) => {
        resolve(users);
      });
    });
  });

  return Promise.all(promises).then((values) => {
    const sessions = [];

    values.forEach((users) => {
      if (!users.empty) {
        users.forEach((doc) => {
          const user = doc.data();
          sessions.push(user);
        });
      }
    });

    return sessions;
  });
};

export const listen = db;
