import Immutable from 'seamless-immutable';

export const Types = {
  CHANGE_STATUS: 'restaurantDocument/CHANGE_STATUS',
  CHANGE_STATUS_SUCCESS: 'restaurantDocument/CHANGE_STATUS_SUCCESS',
  CHANGE_STATUS_FAILED: 'restaurantDocument/CHANGE_STATUS_FAILED',
};

const INITIAL_STATE = Immutable({
  loading: false,
});

export default function restaurantDocument(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CHANGE_STATUS: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.CHANGE_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.CHANGE_STATUS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  changeStatus: (payload) => ({
    type: Types.CHANGE_STATUS,
    payload,
  }),

  changeStatusSuccess: (payload) => ({
    type: Types.CHANGE_STATUS_SUCCESS,
    payload,
  }),

  changeStatusFailed: (payload) => ({
    type: Types.CHANGE_STATUS_FAILED,
    payload,
  }),
};
