import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import api from '../../services/api';
import { Creators as RestaurantActions } from '../ducks/restaurants';

export function* getRestaurant() {
  try {
    const { status, data } = yield call(api.get, '/restaurants');

    if (status === 200) {
      const { data: specialties } = yield call(api.get, '/specialties');
      const { data: payments } = yield call(api.get, '/payment-methods');

      yield put(
        RestaurantActions.getRestaurantSuccess({
          restaurant: data.resource,
          specialties: specialties.data,
          payments: payments.data,
        }),
      );

      yield put(RestaurantActions.getRestaurantImage(data.resource));
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error(
        'Houve um erro ao buscar as informações deste restaurante, tente novamente mais tarde!',
      );
    }
    yield put(RestaurantActions.getRestaurantFailed());
  }
}

export function* getRestaurantImage({ payload }) {
  let logo = null;
  try {
    const { data: imgs } = yield call(api.get, `/restaurants/${payload.id}/images`);

    if (imgs.data[0]) {
      logo = imgs.data[0].filename;
    }

    yield put(
      RestaurantActions.getRestaurantImageSuccess({
        logo,
      }),
    );
  } catch (error) {
    message.warning('O seu restaurante ainda não possui uma imagem.');
    yield put(RestaurantActions.getRestaurantImageFailed());
  }
}

export function* editRestaurant({ payload }) {
  try {
    const { data: restaurant, status } = yield call(
      api.put,
      `/restaurants/${payload.id}`,
      payload,
    );

    if (status === 200) {
      const { data: payments } = yield call(api.get, '/payment-methods');
      message.success('As informações do restaurante foram atualizadas!');
      yield put(
        RestaurantActions.updateRestaurantSuccess({
          ...restaurant.resource,
          payments: payments.data,
        }),
      );
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error(
        'Houve um erro ao editar as informações do restaurante, tente novamente mais tarde!',
      );
    }
    yield put(RestaurantActions.updateRestaurantFailed());
  }
}

export function* uploadImage({ payload }) {
  try {
    const { data, status } = yield call(
      api.post,
      `/restaurants/${payload.id}/images`,
      payload,
    );

    if (status === 200) {
      message.success('A imagem do restaurante foi atualizada!');
      yield put(RestaurantActions.uploadImageSuccess(data.image_url));
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error(
        'Houve um erro ao fazer upload da imagem do restaurante, tente novamente mais tarde!',
      );
    }
    yield put(RestaurantActions.uploadImageFailed());
  }
}

export function* uploadDocument({ payload }) {
  try {
    const { data, status } = yield call(
      api.post,
      `/restaurants/${payload.id}/documents`,
      payload,
    );

    if (status === 200) {
      message.success('Documento enviado!');
      yield put(RestaurantActions.uploadDocumentSuccess(data.document_url));
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error(
        'Houve um erro ao fazer upload do documento do restaurante, tente novamente mais tarde!',
      );
    }
    yield put(RestaurantActions.uploadDocumentFailed());
  }
}

export function* getRestaurants() {
  try {
    const { data, status } = yield call(api.get, '/restaurants');

    if (status === 200) {
      yield put(RestaurantActions.getRestaurantsSuccess(data.data));
    }
  } catch (error) {
    yield put(RestaurantActions.getRestaurantsFailed(error.message));
  }
}

export function* getSimpleList() {
  try {
    const { data } = yield call(api.get, '/restaurants/list');
    yield put(RestaurantActions.getSimpleListFinished(data.data));
  } catch (error) {
    message.error('Não foi possível recuperar a lista de restaurantes!');
  }
}

export function* toogleActive({ payload: restaurant }) {
  try {
    restaurant.visible_app_backoffice = !restaurant.visible_app_backoffice;

    const { data, status } = yield call(api.put, `/restaurants/${restaurant.id}/visible-app`);

    if (status === 204) {
      yield put(RestaurantActions.toggleActiveSuccess(restaurant));
    }
  } catch (error) {
    yield put(RestaurantActions.toggleActiveFailed(error.message));
  }
}

export function* deleteRestaurant({ payload: restaurant }) {
  try {
    const { data, status } = yield call(api.delete, `/restaurants/${restaurant.id}`);

    if (status === 204) {
      yield put(RestaurantActions.deleteRestaurantSuccess(restaurant.id));
    }

    if (data.success === false) {
      yield put(RestaurantActions.deleteRestaurantFailed(data.message));
    }
  } catch (error) {
    yield put(RestaurantActions.deleteRestaurantFailed(error.message));
  }
}

export function* changeStatus({ payload: restaurant }) {
  try {
    const { data, status } = yield call(api.put, `/restaurants/${restaurant.record.id}/status`, {
      active_backoffice: restaurant.active_backoffice,
    });

    if (status === 200) {
      yield put(RestaurantActions.changeStatusSuccess(data.data));
    }
  } catch (error) {
    yield put(RestaurantActions.changeStatusFailed(error.message));
  }
}

export function* confirmRestaurant({ payload: restaurant }) {
  try {
    restaurant.active = !restaurant.active;

    const { status } = yield call(api.put, `/restaurants/${restaurant.id}/activate`);

    if (status === 204) {
      yield put(RestaurantActions.confirmRestaurantSuccess(restaurant));
    }
  } catch (error) {
    yield put(RestaurantActions.confirmRestaurantFailed(error.message));
  }
}
