import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import * as Antd from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  HourglassOutlined,
  LinkOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import * as Styles from '../styles';

import * as DocumentConstants from '../../../../constants/documents';
import { Creators as Actions } from '../../../../store/ducks/restaurantDocument';

const { confirm } = Antd.Modal;

const Document = ({
  documents,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.restaurantDocument);

  const changeStatus = (status, document) => {
    confirm({
      content: 'Você tem certeza que deseja continuar?',
      icon: <ExclamationCircleOutlined />,
      title: `${status === DocumentConstants.STATUS_APPROVED ? 'Aprovar' : 'Reprovar'} documento!`,
      okText: 'Sim, continuar',
      cancelText: 'Não, cancelar',
      centered: true,
      onOk() {
        dispatch(
          Actions.changeStatus({
            status,
            documentId: document.id,
          }),
        );
      },
    });
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'type',
      key: 'type',
      render: (_, document) => DocumentConstants.LABELS[document.type],
    },
    {
      title: 'Data do envio',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      render: (_, document) => moment(document.created_at).format('DD/MM/Y hh:mm'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (_, document) => {
        let statusIcon = null;

        switch (document.status) {
          case DocumentConstants.STATUS_DENIED:
            statusIcon = (
              <>
                <CloseCircleOutlined
                  style={{ color: 'red', marginRight: 10 }}
                />
                Reprovado
              </>
            );
            break;

          case DocumentConstants.STATUS_PENDING:
            statusIcon = (
              <>
                <HourglassOutlined style={{ marginRight: 10 }} />
                Pendente de aprovação
              </>
            );
            break;

          default:
            statusIcon = (
              <>
                <CheckCircleOutlined
                  style={{ color: 'green', marginRight: 10 }}
                />
                Aprovado
              </>
            );
            break;
        }

        return statusIcon;
      },
    },
    {
      title: 'Ações',
      key: 'actions',
      dataIndex: 'actions',
      align: 'center',
      render: (_, document) => (
        <>
          <Antd.Button
            style={{ marginRight: 10 }}
            href={document.filename}
            target="_blank"
            icon={<LinkOutlined />}
            size="small"
          >
            Visualizar
          </Antd.Button>

          {document.status === DocumentConstants.STATUS_PENDING && (
            <>
              <Antd.Button
                style={{ marginRight: 10 }}
                type="primary"
                icon={<CheckCircleOutlined />}
                size="small"
                disabled={loading}
                loading={loading}
                onClick={() => {
                  changeStatus(DocumentConstants.STATUS_APPROVED, document);
                }}
              >
                Aprovar
              </Antd.Button>

              <Antd.Button
                danger
                type="primary"
                size="small"
                icon={<CloseCircleOutlined />}
                disabled={loading}
                loading={loading}
                onClick={() => {
                  changeStatus(DocumentConstants.STATUS_DENIED, document);
                }}
              >
                Reprovar
              </Antd.Button>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <Styles.Table
      title={() => <b>Documentos enviados pelo restaurante</b>}
      bordered
      size="small"
      rowKey={(value) => value.id}
      columns={columns}
      dataSource={documents || []}
      locale={{ emptyText: 'Nenhum documento encontrado' }}
      pagination={false}
      scroll={{ y: 240 }}
    />
  );
};

export default Document;
