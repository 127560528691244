import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Row,
  Col,
  Checkbox,
  Modal,
  Form,
  Button,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Tag,
  Tooltip,
} from 'antd';

import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  CheckOutlined,
} from '@ant-design/icons';

import { Creators as CouponActions } from '../../../../../../store/ducks/coupons';
import InputGroup from '../../../../../../components/InputGroup';

const { Option } = Select;

const layout = {
  wrapperCol: { span: 24 },
};

const CouponForm = (props) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [valueType, setValueType] = useState('R$');
  const [winProduct, setWinProduct] = useState(false);
  const [arrItems, setArrayItems] = useState([]);
  const { data: customers } = useSelector((state) => state.customers);
  const { data: restaurants } = useSelector((state) => state.restaurants);

  const { visible, coupon, saving } = props;

  useEffect(() => {
    setDisabled(true);

    if (visible) {
      if (coupon && coupon.id) {
        
        const couponRestaurants = [];
        if (coupon.restaurants) {
          coupon.restaurants.map(
            (restaurant) => couponRestaurants.push(restaurant.id || restaurant),
          );
        }

        form.setFieldsValue({
          ...coupon,
          start_date: coupon.start_date ? moment(coupon.start_date) : null,
          end_date: coupon.end_date ? moment(coupon.end_date) : null,
          restaurants: couponRestaurants,
        });

        handleChangeType(coupon.type_id);

        setArrayItems(() => coupon.items);

        return;
      }

      form.resetFields();
    }
  }, [visible, coupon, form]);

  const onFinish = (values) => {
    let items = values.items || [];
    if (!values.win_product) {
      items = [];
    }

    const newValues = {
      ...values,
      start_date: values.start_date ? moment(values.start_date).format('YYYY-MM-DD HH:mm:ss') : null,
      end_date: values.end_date ? moment(values.end_date).format('YYYY-MM-DD HH:mm:ss') : null,
      items,
      accumulative: 0,
      only_bonus: 0,
      visible_app: 0,
    };

    if (coupon && coupon.id) {
      dispatch(
        CouponActions.editCoupon({
          id: coupon.id,
          accumulative: 0,
          only_bonus: 0,
          visible_app: 0,
          ...newValues,
        }),
      );
    } else {
      dispatch(CouponActions.createCoupon(newValues));
    }

    setDisabled(true);
  };

  const generateCoupon = () => {
    const code = (new Array(20)).fill(null).map(
      () => String.fromCharCode(Math.floor(Math.random() * 26) + 65),
    ).join('');

    form.setFieldsValue({
      ...coupon,
      code,
    });
  };

  const handleVisibility = () => {
    dispatch(CouponActions.handleModalVisibility(false));
  };

  const handleChangeType = (value) => {
    setDisabled(false);

    const types = {
      1: 'R$',
      2: '%',
    };

    setValueType(types[value]);
  };

  const disabledTime = (current) => {
    let startDate = form.getFieldValue('start_date');

    if (current && startDate) {
      startDate = moment(startDate);

      if (!current.isSame(startDate, 'date')) {
        return false;
      }

      let minutes = false;
      if (current.format('H') === startDate.format('H')) {
        minutes = [...Array(60).keys()].slice(0, startDate.format('m'));
      }

      return {
        disabledHours: () => [...Array(24).keys()].slice(0, startDate.format('H')),
        disabledMinutes: () => minutes,
      };
    }

    return false;
  };

  const disabledDate = (current) => current && current < moment().subtract(1, 'days');

  return (
    <Modal
      title={coupon && coupon.id ? 'Editar cupom' : 'Cadastrar cupom'}
      maskClosable={false}
      centered
      visible={visible}
      onCancel={() => handleVisibility()}
      footer={[
        <Button key="back" onClick={() => handleVisibility()}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={disabled}
          loading={saving}
          onClick={() => form.submit()}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={{
          max_usages: 0,
          min_price: 0,
          accumulative: 0,
          only_first_order: 0,
          win_product: 0,
          active: 1,
          restaurants: [],
          customers: [],
          type: 'absolute',
          start_date: null,
          end_date: null,
          visible_app: 0,
        }}
        onFinish={onFinish}
        onChange={() => setDisabled(false)}
        layout="vertical"
      >
        <Form.Item
          name="title"
          label="Nome"
          rules={[{ required: true, message: 'O nome do cupom é obrigatório!' }]}
        >
          <Input maxLength="190" />
        </Form.Item>

        <Row justify="space-between">
          <Col span={16}>
            <Form.Item
              name="code"
              label="Código"
              disabled={coupon && coupon.id}
              rules={[{ required: true, message: 'O nome do cupom é obrigatório!' }]}
            >
              <Input maxLength="190" />
            </Form.Item>
          </Col>

          {!coupon?.id && (
            <Col span={6}>
              <Tag
                style={{ borderRadius: 10, marginTop: 32, fontWeight: 500, cursor: 'pointer' }}
                color="green"
                onClick={() => generateCoupon()}
              >
                Gerar &nbsp;
                <CheckOutlined style={{ fontSize: 13 }} />
              </Tag>
            </Col>
          )}
        </Row>

        <Row justify="space-between">
          <Col span={7}>
            <Form.Item label="Tipo" name="type_id">
              <Select onChange={handleChangeType}>
                <Select.Option key="1" value={1}>
                  Absoluto (R$)
                </Select.Option>
                <Select.Option key="2" value={2}>
                  Relativo (%)
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={15}>
            <Form.Item
              label="Valor"
              name="value"
              rules={[
                { required: true, message: 'É obrigatório informar um valor para o cupom!' },
              ]}
            >
              <InputGroup
                prefix={valueType}
                style={{ width: '50%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={22}>
            <Form.Item
              name="restaurants"
              label="Restaurante(s)"
              hasFeedback
            >
              <Select
                mode="multiple"
                placeholder="Selecione o(s) restaurante(s)"
                onChange={() => setDisabled(false)}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(
                  input.toLowerCase(),
                ) >= 0}
              >
                {restaurants.map((restaurant) => (
                  <Option key={restaurant.id} value={restaurant.id}>
                    {restaurant.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={13}>
            <Form.Item label="Máximo de usos" name="max_uses">
              <InputNumber
                min={0}
                max={1000000}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label="Usos"
              name="used"
            >
              <InputNumber
                disabled
                min={0}
                max={1000000}
                style={{ width: '70%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={22}>
            <Form.Item label="Preço mínimo no Carrinho" name="min_price">
              <InputGroup
                mask="999.99"
                placeholder="Valor"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={12}>
            <Form.Item
              label="Data de início"
              name="start_date"
            >
              <DatePicker
                showTime
                onChange={() => setDisabled(false)}
                allowClear
                allowEmpty
                format="DD/MM/YYYY HH:mm"
                placeholder="Data de início"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Data de encerramento"
              name="end_date"
            >
              <DatePicker
                showTime
                allowClear
                allowEmpty
                format="DD/MM/YYYY HH:mm"
                placeholder="Data de encerramento"
                onChange={() => setDisabled(false)}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={12}>
            <Tooltip placement="top" title="Cupom pode ser aplicado somente no primeiro pedido do consumidor">
              <Form.Item
                name="only_first_order"
                valuePropName="checked"
                style={{ marginTop: '10px' }}
              >
                <Checkbox>Somente primeiro pedido</Checkbox>
              </Form.Item>
            </Tooltip>
          </Col>

          <Col span={8}>
            <Form.Item name="active" valuePropName="checked" style={{ marginTop: '10px' }}>
              <Checkbox>Ativo</Checkbox>
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  );
};

CouponForm.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    customers: PropTypes.array,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    restaurants: PropTypes.array,
  }),
  visible: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
};

CouponForm.defaultProps = {
  coupon: null,
};

export default CouponForm;
