import styled from 'styled-components';
import media from 'styled-media-query';

export const Content = styled.div`
  overflow: auto;
  max-height: 52rem;

  ${media.between('large', 'huge')`
    max-height: 36rem;
  `}

  ${media.greaterThan('1800px')`
    max-height: 47rem;
  `}

  ${media.lessThan('small')`
    max-height: 30rem;
  `}
`;
