import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';

import { Creators as CouponActions } from '../../../../../../store/ducks/coupons';

const CouponExtra = () => {
  const dispatch = useDispatch();
  return (
    <div>
      <Button
        onClick={() => {
          dispatch(CouponActions.resetCoupon());
          dispatch(CouponActions.handleModalVisibility(true));
        }}
        style={{ borderRadius: 20 }}
        type="primary"
      >
        Adicionar Cupom
      </Button>
    </div>
  );
};

export default CouponExtra;
