import styled from 'styled-components';
import { Layout, Tabs } from 'antd';

export const Wrapper = styled(Layout)`
  padding: 10px 30px 0px 30px;
  flex: 1;
  height: 100%;
  overflow: auto;
`;

export const TabPainel = styled(Tabs)`
  .ant-tabs-nav {
    margin-left: 30px;
    margin-top: 10px;
  }

  .ant-tabs-bar {
    padding-top: 20px;
    padding-left: 30px;
    margin-bottom: 0px;
  }
`;
