import { call, put } from 'redux-saga/effects';
import { message } from 'antd';

import api from '../../services/api';
import { Creators as MenuActions } from '../ducks/menu';

export function* getRestaurantMenu({ payload: id }) {
  try {
    const { data, status } = yield call(api.get, `/menu/restaurant/${id}`);

    if (status === 200) {
      yield put(MenuActions.getRestaurantMenuSuccess(data.data));
    }
  } catch (error) {
    message.error(
      'Houve um erro ao listar os pratos desse restaurante. Tente novamente!',
    );

    yield put(MenuActions.getRestaurantMenuFailed());
  }
}