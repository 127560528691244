import firebase from 'firebase/app';
import 'firebase/firestore';

firebase.initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
});

const db = firebase.firestore();

if (process.env.REACT_APP_FIREBASE_EMULATOR_ACTIVE === 'true') {
  db.useEmulator(
    process.env.REACT_APP_FIREBASE_EMULATOR_HOST,
    process.env.REACT_APP_FIREBASE_EMULATOR_PORT
  );
}

export default db;
