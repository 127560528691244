import { message } from 'antd';

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('É permitido apenas arquivos do tipo JPG/PNG');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('É permitido apenas imagens menores que 2MB');
  }
  return isJpgOrPng && isLt2M;
}

export function isAuthenticated(user) {
  const token = localStorage.getItem('access_token');
  return user && token;
}

export function getPrice(itemPrice) {
  let price = 0.0;
  if (itemPrice) price = itemPrice;
  return parseFloat(price).toFixed(2).toString().replace('.', ',');
}

export function getCurrency(value) {
  let price = 0;
  if (value) price = value;

  return price.toLocaleString('pt-br', { minimumFractionDigits: 2 });
}

export const formatPhone = (str) => {
  const cleaned = (`${str}`).replace(/\D/g, '');
  let regex = /^(\d{2})(\d{2})(\d{4})(\d{4})$/;

  if (cleaned.length > 12) {
    regex = /^(\d{2})(\d{2})(\d{5})(\d{4})$/;
  }

  const match = cleaned.match(regex);

  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }

  return null;
};

export function validateCnpj(s) {
  const cnpj = s.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14) return false;

  if (/^(\d)\1+$/.test(cnpj)) return false;

  const t = cnpj.length - 2;
  const d = cnpj.substring(t);
  const d1 = parseInt(d.charAt(0));
  const d2 = parseInt(d.charAt(1));
  const calc = (x) => {
    const n = cnpj.substring(0, x);
    let y = x - 7;
    let s = 0;
    let r = 0;

    for (let i = x; i >= 1; i--) {
      s += n.charAt(x - i) * y--;
      if (y < 2) y = 9;
    }

    r = 11 - (s % 11);
    return r > 9 ? 0 : r;
  };

  return calc(t) === d1 && calc(t + 1) === d2;
}

export function formatCpf(cpf) {
  if (cpf && cpf.trim().length === 11) {
    const value = cpf.replace(/[^\d]/g, '');
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  return cpf || '';
}

export function formatCnpj(cnpj) {
  if (cnpj && cnpj.trim().length === 14) {
    const value = cnpj.replace(/[^\d]/g, '');
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  return cnpj || '';
}

export function formatDocument(document) {
  if (!document) {
    return document;
  }

  if (document.trim().length === 11) {
    return formatCpf(document);
  }

  return formatCnpj(document);
}

export function isExpired(creationDate, minutes) {
  if (!creationDate) return false;
  const newMinutes = minutes || 10;

  const createOrder = new Date(creationDate).getTime();
  const expirationTime = createOrder + (newMinutes * 60 * 1000);

  return (expirationTime < new Date().getTime());
}

export const matchGuideRedText = (text) => {
  if (!text) {
    return null;
  }

  let response = text;

  const match = {
    regex: /<red>(.*?)<\/red>/,
    start: '<span style="color: red;">',
    end: '</span>',
  };

  const parts = response.match(match.regex);

  if (parts && parts.length > 0) {
    response = response.replace(parts[0], `${match.start}${parts[1]}${match.end}`);
  }

  return response;
};

export const formatGuideText = (value) => {
  let response = value;

  const matches = [
    { isReplace: true, regex: /<p>/, replace: '' },
    { isReplace: true, regex: /<\/p>/, replace: '' },
    { isReplace: true, regex: /<strong>/, replace: '<b>' },
    { isReplace: true, regex: /<\/strong>/, replace: '</b>' },
    { isReplace: true, regex: /<em>/, replace: '<i>' },
    { isReplace: true, regex: /<\/em>/, replace: '</i>' },
    {
      isReplace: false,
      regex: /<span\s(?:style="color: red;")>(.*?)<\/span>/,
      start: '<red>',
      end: '</red>' },
    {
      isReplace: false,
      regex: /<span\s(?:style="text-decoration: underline;")>(.*?)<\/span>/,
      start: '<u>',
      end: '</u>',
    },
  ];

  const htmlElement = document.createElement('textarea');
  htmlElement.innerHTML = value;
  const textDecoded = htmlElement.value;

  response = textDecoded;

  matches.forEach((match) => {
    if (match.isReplace) {
      response = response.replace(new RegExp(match.regex, 'g'), match.replace);
    } else {
      const parts = response.match(match.regex);

      if (parts && parts.length > 0) {
        response = response.replace(parts[0], `${match.start}${parts[1]}${match.end}`);
      }
    }
  });

  return response;
};

export const isFileValidSize = (file, maxSize = 1) => {
  if (file.size / 1024 / 1024 > maxSize) {
    return false;
  }

  return true;
};
