import Immutable from 'seamless-immutable';

export const Types = {
  GET_SETTINGS_REQUEST: 'settings/GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'settings/GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILED: 'settings/GET_SETTINGS_FAILED',

  UPDATE_SETTINGS_REQUEST: 'settings/UPDATE_SETTINGS_REQUEST',
  UPDATE_SETTINGS_SUCCESS: 'settings/UPDATE_SETTINGS_SUCCESS',
  UPDATE_SETTINGS_FAILED: 'settings/UPDATE_SETTINGS_FAILED',
};

const INITIAL_STATE = Immutable({
  loading: false,
  saving: false,
  data: {},
});

export default function settings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_SETTINGS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }

    case Types.GET_SETTINGS_FAILED: {
      return {
        ...state,
        loading: false,
        data: {},
      };
    }

    case Types.UPDATE_SETTINGS_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.UPDATE_SETTINGS_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.UPDATE_SETTINGS_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getSettings: (payload) => ({
    type: Types.GET_SETTINGS_REQUEST,
    payload,
  }),

  getSettingsSuccess: (payload) => ({
    type: Types.GET_SETTINGS_SUCCESS,
    payload,
  }),

  getSettingsFailed: () => ({
    type: Types.GET_SETTINGS_FAILED,
  }),

  saveSettings: (payload) => ({
    type: Types.UPDATE_SETTINGS_REQUEST,
    payload,
  }),

  saveSettingsSuccess: (payload) => ({
    type: Types.UPDATE_SETTINGS_SUCCESS,
    payload,
  }),

  saveSettingsFailed: () => ({
    type: Types.UPDATE_SETTINGS_FAILED,
  }),
};
