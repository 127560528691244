import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import me from './me';
import coupons from './coupons';
import questions from './questions';
import reasons from './reasons';
import restaurants from './restaurants';
import users from './users';
import customers from './customers';
import delivery from './deliveryService';
import orders from './orders';
import menu from './menu';
import monitoring from './monitoring';
import restaurantDocument from './restaurantDocument';
import settings from './settings';

const getReducers = (history) => ({
  router: connectRouter(history),
  coupons,
  questions,
  reasons,
  restaurants,
  auth,
  me,
  users,
  customers,
  delivery,
  orders,
  menu,
  monitoring,
  restaurantDocument,
  settings,
});

const reducers = (history) => combineReducers(getReducers(history));

export default reducers;
