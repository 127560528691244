import styled from 'styled-components';
import { Table, Layout } from 'antd';

export const StyledTable = styled(Table)`
  overflow: auto;
  .ant-table-row {
    background-color: white;
  }
`;

export const Wrapper = styled(Layout)`
  padding: 30px;
  flex: 1;
  height: 100%;
  overflow: auto;

  .ant-steps-item-icon {
    margin-right: 30px;
  }

  .ant-steps-item-title {
    font-size: 25px;
    padding-bottom: 5px;
    color: black !important;
    font-weight: bold;
  }
`;
