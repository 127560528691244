import React, { useState } from 'react';
import { Wrapper, TabPainel } from './styles';
import Monitoring from './components/Monitoring';
import Orders from './components/Orders';

const { TabPane } = TabPainel;

const MonitoringPage = () => {
  const [tab, setTab] = useState('1');

  return (
    <TabPainel animated={false} defaultActiveKey={tab} onChange={(key) => setTab(key)}>
      <TabPane tab="Pedidos" key="1">
        <Wrapper>
          <Orders />
        </Wrapper>
      </TabPane>
      <TabPane tab="Restaurantes" key="2">
        <Wrapper>
          <Monitoring />
        </Wrapper>
      </TabPane>
    </TabPainel>
  );
};

export default MonitoringPage;
