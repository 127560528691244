import { all, takeLatest } from 'redux-saga/effects';
import { Types as CouponTypes } from '../ducks/coupons';
import { Types as QuestionTypes } from '../ducks/questions';
import { Types as ReasonTypes } from '../ducks/reasons';
import { Types as RestaurantTypes } from '../ducks/restaurants';
import { Types as AuthTypes } from '../ducks/auth';
import { Types as MeTypes } from '../ducks/me';
import { Types as UserTypes } from '../ducks/users';
import { Types as CustomerTypes } from '../ducks/customers';
import { login, logout, resetPassword, changePassword } from './auth';
import { Types as DeliveryTypes } from '../ducks/deliveryService';
import { Types as OrderTypes } from '../ducks/orders';
import { Types as MenuTypes } from '../ducks/menu';
import { Types as MonitoringTypes } from '../ducks/monitoring';
import { Types as RestaurantDocumentTypes } from '../ducks/restaurantDocument';
import { Types as SettingTypes } from '../ducks/settings';

import {
  getCoupons,
  createCoupon,
  editCoupon,
  getCoupon,
  deleteCoupon,
} from './coupons';

import {
  getQuestions,
  createQuestion,
  editQuestion,
  deleteQuestion,
  getQuestion,
} from './questions';

import {
  getReasons,
  createReason,
  editReason,
  deleteReason,
} from './reasons';

import { getOrders } from './orders';
import { getAllUsers, createUser, editUser, toggleUserVisibility } from './users';
import { placeDeliveryOrder, cancelDeliveryOrder, geoLocation } from './deliveryService';

import {
  getRestaurants,
  changeStatus,
  confirmRestaurant,
  deleteRestaurant,
  toogleActive,
  getRestaurant,
  getRestaurantImage,
  editRestaurant,
  uploadImage,
  uploadDocument,
  getSimpleList,
  visibleApp,
} from './restaurants';

import { getCustomers, editCustomer } from './customers';
import { getRestaurantMenu } from './menu';
import { getRestaurants as getRestaurantsMonitoring } from './monitoring';
import { changeDocumentStatus } from './restaurantDocument';
import { getSettings, saveSettings } from './settings';

export default function* rootSaga() {
  yield all([
    takeLatest(CouponTypes.GET_COUPONS_REQUEST, getCoupons),
    takeLatest(CouponTypes.GET_COUPON_REQUEST, getCoupon),
    takeLatest(CouponTypes.CREATE_COUPONS_REQUEST, createCoupon),
    takeLatest(CouponTypes.EDIT_COUPON_REQUEST, editCoupon),
    takeLatest(CouponTypes.DELETE_COUPON_REQUEST, deleteCoupon),

    takeLatest(QuestionTypes.GET_QUESTIONS_REQUEST, getQuestions),
    takeLatest(QuestionTypes.CREATE_QUESTIONS_REQUEST, createQuestion),
    takeLatest(QuestionTypes.EDIT_QUESTION_REQUEST, editQuestion),
    takeLatest(QuestionTypes.DELETE_QUESTION_REQUEST, deleteQuestion),
    takeLatest(QuestionTypes.GET_QUESTION_REQUEST, getQuestion),

    takeLatest(ReasonTypes.GET_REASONS, getReasons),
    takeLatest(ReasonTypes.CREATE_REASON, createReason),
    takeLatest(ReasonTypes.EDIT_REASON, editReason),
    takeLatest(ReasonTypes.DELETE_REASON, deleteReason),

    takeLatest(DeliveryTypes.PLACE_DELIVERY_ORDER_REQUEST, placeDeliveryOrder),
    takeLatest(DeliveryTypes.CANCEL_DELIVERY_ORDER_REQUEST, cancelDeliveryOrder),
    takeLatest(DeliveryTypes.GEO_LOCATION_REQUEST, geoLocation),

    takeLatest(OrderTypes.GET_ORDERS_REQUEST, getOrders),

    takeLatest(AuthTypes.GET_AUTH_REQUEST, login),
    takeLatest(AuthTypes.RESET_PASSWORD_REQUEST, resetPassword),
    takeLatest(AuthTypes.CHANGE_PASSWORD_REQUEST, changePassword),

    takeLatest(MeTypes.REMOVE_AUTH_USER_REQUEST, logout),

    takeLatest(UserTypes.GET_ALL_USERS_REQUEST, getAllUsers),
    takeLatest(UserTypes.TOGGLE_USER_VISIBILITY_REQUEST, toggleUserVisibility),
    takeLatest(UserTypes.CREATE_USER_REQUEST, createUser),
    takeLatest(UserTypes.EDIT_USER_REQUEST, editUser),

    takeLatest(RestaurantTypes.GET_RESTAURANT_REQUEST, getRestaurant),
    takeLatest(RestaurantTypes.GET_RESTAURANT_IMAGE_REQUEST, getRestaurantImage),
    takeLatest(RestaurantTypes.UPDATE_RESTAURANT_REQUEST, editRestaurant),
    takeLatest(RestaurantTypes.UPLOAD_IMAGE_REQUEST, uploadImage),
    takeLatest(RestaurantTypes.UPLOAD_DOCUMENT_REQUEST, uploadDocument),
    takeLatest(RestaurantTypes.GET_RESTAURANTS_REQUEST, getRestaurants),
    takeLatest(RestaurantTypes.CHANGE_STATUS_REQUEST, changeStatus),
    takeLatest(RestaurantTypes.CONFIRM_RESTAURANT_REQUEST, confirmRestaurant),
    takeLatest(RestaurantTypes.DELETE_RESTAURANT_REQUEST, deleteRestaurant),
    takeLatest(RestaurantTypes.TOGGLE_ACTIVE_REQUEST, toogleActive),
    takeLatest(RestaurantTypes.GET_SIMPLE_LIST_REQUEST, getSimpleList),

    takeLatest(CustomerTypes.GET_CUSTOMERS_REQUEST, getCustomers),
    takeLatest(CustomerTypes.EDIT_CUSTOMER_REQUEST, editCustomer),

    takeLatest(MenuTypes.GET_RESTAURANT_MENU_REQUEST, getRestaurantMenu),

    takeLatest(MonitoringTypes.GET_RESTAURANTS_REQUEST, getRestaurantsMonitoring),

    takeLatest(RestaurantDocumentTypes.CHANGE_STATUS, changeDocumentStatus),

    takeLatest(SettingTypes.GET_SETTINGS_REQUEST, getSettings),
    takeLatest(SettingTypes.UPDATE_SETTINGS_REQUEST, saveSettings),
  ]);
}
