import styled from 'styled-components';
import { Col, Tag, Table } from 'antd';
import media from 'styled-media-query';

export const Wrapper = styled(Col)`
  margin-bottom: 30px;
  .ant-card-body {
    padding: 3px;
  }
`;

export const Content = styled.div`
  thead[class*="ant-table-thead"] th{
    background-color: #f2f2f2;
  }

  .nested-order-table table{
    border: 1px solid #e2e2e2
  }

  ${media.between('large', 'huge')`
    max-height: 36rem;
  `}

  ${media.greaterThan('1800px')`
    max-height: 47rem;
  `}

  ${media.lessThan('small')`
    max-height: 30rem;
  `}
`;


export const CustomTag = styled(Tag)`
  border-radius: 2px;
  padding: 7px 10px;
  min-width: 250px;
  margin: 5px auto 5px 15px;
  font-weight: 500;
  cursor: pointer;
`;


export const CustomTable = styled(Table)`
  table {
    overflow: hidden;

    tr:nth-child(2n) td {
      background-color: #fbfbfb;
    }
  }
`;
