import React from 'react';
import moment from 'moment';

import {
  Avatar,
  Tag,
  Badge,
  Button,
  Space,
  Input,
  Col,
  Row,
  Popover,
} from 'antd';

import {
  UserOutlined,
  PhoneOutlined,
  SearchOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import { getPrice, formatDocument } from '../../../../../configs/utils';

import {
  STATUS_DESCRIPTIONS,
  DELIVERY_SERVICE_DESCRIPTIONS,
  TYPE_DELIVERY,
  DELIVERY_PARTNER_LALAMOVE,
} from '../../../../../constants/orders';

const getOptionTitle = (option) => {
  return `${option.group} - ${option.quantity > 1 ? option.quantity : ''} ${option.title}`;
};

const getFullAddress = (address) => {
  const items = [
    address.street,
    address.number,
    address.complement,
    address.neighborhood,
  ];

  const partialAddress = items.filter(Boolean).join(' - ');

  return (
    <span style={{ fontSize: '12px' }}>
      {partialAddress}
      <br />
      CEP:
      { ' ' }
      {address.zipcode}
    </span>
  );
};

const getColumnSearchProps = (dataIndex, subIndex, placeholder, searchInput) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          searchInput = node;
        }}
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Procurar
        </Button>
        <Button
          onClick={() => clearFilters()}
          size="small"
          style={{ width: 90 }}
          icon={<CloseCircleOutlined />}
        >
          Limpar
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) => {
    if (subIndex) {
      return (
        record[dataIndex][subIndex]
          ? record[dataIndex][subIndex].toString().toLowerCase().includes(value.toLowerCase())
          : ''
      );
    }

    return (
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''
    );
  },
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
});

const handleDeliveryInfo = (order) => {
  return (
    <Tag style={{ borderRadius: 5, minWidth: '250px' }} color="orange">
      Serviço de entrega:
      {' '}
      <strong>
        {DELIVERY_SERVICE_DESCRIPTIONS[order.delivery_info.type_id]}
      </strong>
      <br />
      Código para rastreio:
      {' '}
      <strong>{order.delivery_info.transaction_code}</strong>
      <br />
      Taxa cobrada: <strong>{`R$ ${getPrice(order.delivery_info.value)}`}</strong>
    </Tag>
  );
};

const handleDriverInfo = (order) => {
  const deliveryInfo = handleDeliveryInfo(order);

  if (!order.delivery_driver) {
    return deliveryInfo;
  }

  const { photoUrl, name, phone, licensePlate } = order.delivery_driver;

  if (!name && !phone && !licensePlate) {
    return deliveryInfo;
  }

  return (
    <Popover
      title="Informações do entregador"
      content={
        <Row gutter={12}>
          <Col>
            <img
              height="60"
              alt="Entregador"
              src={photoUrl}
            />
          </Col>
          <Col>
            <b>{name}</b>
            <br />
            Placa: {licensePlate}
            <br />
            Telefone: {phone}
          </Col>
        </Row>
      }
    >
      {deliveryInfo}
    </Popover>
  );
};

export const items = [
  {
    title: 'Nome',
    dataIndex: 'title',
    key: 'name',
    render: (_, item) => (
      <>
        <span style={{ fontWeight: 500, color: 'black' }}>{item.title}</span>
        
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {item.options.map((option) => (
            <Badge
              key={option.id}
              color="orange"
              text={
                <small>
                  {getOptionTitle(option)}
                  <Tag
                    style={{
                      borderRadius: 10,
                      fontSize: '100%',
                      marginLeft: '5px',
                      marginTop: '3px',
                    }}
                    color="#87d068"
                  >
                    {`R$ ${getPrice(option.quantity * option.price)}`}
                  </Tag>
                </small>
              }
            />
          ))}
        </div>
      </>
    ),
  },
  {
    title: 'Preço Unitário',
    dataIndex: 'price',
    key: 'price',
    render: (price) => (
      <Tag style={{ borderRadius: 10 }} color="green">{`R$ ${getPrice(price)}`}</Tag>
    ),
  },
  {
    title: 'Quantidade',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (quantity) => (
      <Tag style={{ borderRadius: 10 }} color="red">
        {quantity ? quantity : 0}
      </Tag>
    ),
  },
  {
    title: 'Observação',
    dataIndex: 'notes',
    key: 'notes',
    render: (notes) => <small>{notes ? notes : 'Sem observações'}</small>,
  },
];

export const orders = [
  {
    title: 'Nº Pedido',
    dataIndex: 'id',
    key: 'order',
    responsive: ['sm', 'md', 'lg'],
    render: (text) => (
      <Tag color="geekblue" style={{ borderRadius: 10 }}>
        {text}
      </Tag>
    ),
    ...getColumnSearchProps('id', null, 'Pesquisar por pedido', {}),
  },
  {
    title: 'Restaurante',
    dataIndex: 'restaurant',
    key: 'restaurant',
    onHeaderCell: () => ({
      style: ({
        minWidth: 200,
      }),
    }),
    responsive: ['sm', 'md', 'lg'],
    render: (restaurant) => (
      <>
        {restaurant ? restaurant.name : 'Sem restaurante'}
      </>
    ),
    ...getColumnSearchProps('restaurant', 'name', 'Pesquisar por restaurante', {}),
  },
  {
    title: 'Contato',
    dataIndex: 'customer',
    key: 'customer',
    onHeaderCell: () => ({
      style: ({
        minWidth: 220,
      }),
    }),
    responsive: ['sm', 'md', 'lg'],
    render: (customer, order) => (
      <>
        <UserOutlined />
        &nbsp;
        <span style={{ fontWeight: 500, color: 'black' }}>{(customer.name)}</span> <br></br>

        <PhoneOutlined />
        &nbsp;
        <span style={{ color: 'black' }}>{customer.cellphone}</span>

        {order.delivery_type_id === TYPE_DELIVERY && order.delivery_address && (
          <div>
            <br></br>
            <strong style={{ fontSize: '12px' }}>Endereço de Entrega: </strong>
            <br></br>
            {getFullAddress(order.delivery_address)}
          </div>
        )}
      </>
    ),
  },
  {
    title: 'Informações',
    dataIndex: 'info',
    key: 'info',
    responsive: ['sm', 'md', 'lg'],
    render: (_, data) => (
      <div>
        <Tag style={{ borderRadius: 5 }} color="red">Status: {STATUS_DESCRIPTIONS[data.status_id]}</Tag> <br></br>

        {
          (data.invoice_document && data.invoice_document.trim().length > 0) && (
            <>
              <Tag style={{ borderRadius: 5 }} color="red">CPF/CNPJ para Nota Fiscal: {formatDocument(data.invoice_document)}</Tag>
                <br />
            </>
          )
        }

        <Tag color="magenta" style={{ borderRadius: 5, zIndex: 99 }}>
          Criado em: {moment(data.created_at).format('DD/MM/YYYY HH:mm')}
        </Tag> <br></br><br></br>

        <Tag style={{ borderRadius: 5 }} color="lightgray" style={{color: "black", borderRadius: '8px'}}>
          Subtotal: {`R$ ${getPrice(data.item_total)}`} <br></br>
          {
              data.discount_total &&
              <>
                Descontos: {`R$ ${getPrice(data.discount_total)}`} <br></br>
              </>
          }

          Total: <strong>{`R$ ${getPrice(data.total)}`}</strong>
        </Tag>
        <br></br>

        {
          data.delivery_info && data.delivery_info.type_id === DELIVERY_PARTNER_LALAMOVE &&
          <>
            <br></br>
            {handleDriverInfo(data)}
          </>
        }
      </div>
    ),
  },
];
