import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  Button,
  Col,
  Form,
  Input,
  Select,
  Switch,
} from 'antd';

import {
  LockOutlined,
  MailOutlined,
  UserOutlined,
  SaveOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import { Creators as UserCreators } from '../../../../store/ducks/users';

import {
  ROLES,
} from '../../../../constants/roles';
import { PasswordRuleAlert, PasswordRuleAlertSub } from './styles';

const { Option } = Select;

const UserForm = ({ visible, user }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { saving, data } = useSelector((state) => state.users);
  const edit = user ? data.find((u) => u.id === user.key) : null;
  const { user: userSession } = useSelector((state) => state.me);

  useEffect(() => {
    const getValues = () => {
      if (edit) {
        return {
          ...edit,
          perfil: edit.role_id.toString(),
        };
      }

      return null;
    };

    if (visible) {
      form.setFieldsValue(getValues());
    } else {
      form.resetFields();
    }
  }, [visible, form, edit, user]);

  const onFinish = (values) => {
    const dataCad = {
      name: values.name,
      username: values.userName,
      email: values.email,
      password: values.password,
      password_confirmation: values.password_confirmation,
      active: !!values.active,
      role_id: parseInt(values.perfil, 0),
    };

    if (user) {
      dispatch(
        UserCreators.editUser({
          id: user.key,
          role_id: parseInt(values.perfil, 0),
          username: values.userName || edit.username,
          ...values,
        }),
      );
    } else {
      dispatch(UserCreators.createUser(dataCad));
    }
  };

  const handleCloseModal = () => {
    dispatch(UserCreators.handleModalVisibility(false));
    setTimeout(() => form.resetFields(), 500);
  };

  return (
    <Modal
      title={user ? 'Editar Usuário' : 'Adicionar Usuário'}
      centered
      maskClosable={false}
      visible={visible}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          icon={<CloseOutlined />}
        >
          Cancelar
        </Button>,

        <Button
          key="submit"
          type="primary"
          loading={saving}
          disabled={saving}
          onClick={() => form.submit()}
          icon={<SaveOutlined />}
        >
          Salvar
        </Button>,
      ]}
    >
      <Col span={24}>
        <Form
          form={form}
          name="registerUser"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="Nome:"
            rules={[
              {
                required: true,
                message: 'O campo nome é obrigatório!',
              },
            ]}
          >
            <Input
              placeholder="Informe o nome do usuário"
              prefix={<UserOutlined />}
            />
          </Form.Item>

          <Form.Item
            label="E-mail:"
            name="email"
            rules={[
              {
                required: true,
                message: 'O campo e-mail é obrigatório!',
              },
            ]}
          >
            <Input
              placeholder="Informe o e-mail para acesso"
              prefix={<MailOutlined />}
            />
          </Form.Item>

          {(!edit || ((userSession && edit) && userSession.id !== edit.id)) && (
            <Form.Item
              name="perfil"
              label="Perfil:"
              hasFeedback
              rules={[{ required: true, message: 'O perfil é obrigatório!' }]}
            >
              <Select
                placeholder="Selecione o perfil"
              >
                {ROLES.map((role) => (
                  <Option
                    key={role.reference}
                    value={role.reference.toString()}
                  >
                    {role.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {!user && (
            <>
              <Form.Item
                label="Usuário:"
                name="userName"
                rules={[
                  {
                    required: true,
                    message: 'O campo usuário é obrigatório!',
                  },
                ]}
              >
                <Input
                  placeholder="Informe o usuário para acesso"
                  prefix={<UserOutlined />}
                />
              </Form.Item>

              <ul style={PasswordRuleAlert}>
                <li>
                  Regras de senha:
                  <ul style={PasswordRuleAlertSub}>
                    <li>Mínimo de 6 caracteres.</li>
                    <li>Possuir pelo menos uma letra maiúscula.</li>
                    <li>Possuir pelo menos uma letra minúscula.</li>
                    <li>Possuir pelo menos um número.</li>
                    <li>Possuir pelo menos um caracter especial. (Aceitos: @ $ ! % * ? _ - . : & #)</li>
                  </ul>
                </li>
              </ul>

              <Form.Item
                name="password"
                label="Senha:"
                rules={[
                  {
                    required: true,
                    message: 'O campo senha é obrigatório!',
                  },
                ]}
              >
                <Input.Password
                  placeholder="**********"
                  prefix={<LockOutlined />}
                />
              </Form.Item>

              <Form.Item
                name="password_confirmation"
                label="Confirmar Senha:"
                rules={[
                  {
                    required: true,
                    message: 'O campo confirmação de senha é obrigatório!',
                  },
                ]}
              >
                <Input.Password
                  placeholder="**********"
                  prefix={<LockOutlined />}
                />
              </Form.Item>
            </>
          )}

          <Form.Item
            name="active"
            label="Ativo"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  );
};

UserForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  user: PropTypes.shape(),
};

UserForm.defaultProps = {
  user: null,
};

export default UserForm;
