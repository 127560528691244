import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isAuthenticated } from '../configs/utils';

import {
  ROLE_SUPPORT,
} from '../constants/roles';

const PrivateRoute = ({ component: Component, authorize, ...rest }) => {
  const { user } = useSelector((state) => state.me);

  const verifyAuth = (props) => {
    if (isAuthenticated(user)) {
      if (authorize.find((role) => user.role_id === role)) {
        return <Component {...props} />;
      }

      if (user.role_id === ROLE_SUPPORT) {
        return <Redirect to="/orders" />;
      }

      return <Redirect to="/restaurants" />;
    }

    return <Redirect to="/login" />;
  };

  return <Route {...rest} render={(props) => verifyAuth(props)} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  authorize: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default PrivateRoute;
