import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';

import {
  Space,
  Button,
  Input,
  Table,
  Result,
  Tag,
} from 'antd';

import {
  SearchOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  HourglassOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import { formatPhone } from '../../../../../../configs/utils';

const List = (props) => {
  const { restaurants } = props;

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  let searchInput = {};

  const getColumnSearchProps = (dataIndex, render) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder="Procurar"
          value={selectedKeys[0]}
          onChange={
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Procurar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
            icon={<CloseCircleOutlined />}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (
      record[dataIndex]
        ? record[dataIndex].toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : ''
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text, record) => (
      searchedColumn === dataIndex && searchText ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        render(text, record)
      )
    ),
  });

  const columns = [
    {
      title: 'Restaurante',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: {
        compare: (a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          }

          return 0;
        },
        multiple: 1,
      },
      ...getColumnSearchProps('name', (_, record) => (
        <div>
          {record.opened && !record.logged ? (
            <>
              <ExclamationCircleOutlined style={{ marginRight: 5 }} />
              {record.name}
            </>
          ) : record.name}
        </div>
      )),
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
      align: 'left',
      render: (_, record) => formatPhone(record.phone),
    },
    {
      title: 'Horário de abertura',
      dataIndex: 'open_at',
      key: 'open_at',
      align: 'left',
      render: (_, record) => (
        record.opened ? (
          `Aberto desde ${record.open_at}`
        ) : (
          <>
            <HourglassOutlined style={{ marginRight: 5 }} />
            Abertura
            {' '}
            {record.open_at}
          </>
        )
      ),
    },
    {
      title: 'Horário do login',
      dataIndex: 'signed_in_at',
      key: 'signed_in_at',
      align: 'left',
    },
    {
      title: 'Logado no Gestor',
      dataIndex: 'logged',
      key: 'logged',
      align: 'left',
      render: (_, record) => {
        if (record.logged) {
          return (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Sim
            </Tag>
          );
        }

        if (record.opened && !record.logged) {
          return (
            <Tag icon={<WarningOutlined />} color="error">
              Não
            </Tag>
          );
        }

        if (!record.opened && !record.logged) {
          return (
            <Tag icon={<HourglassOutlined />} color="warning">
              Não
            </Tag>
          );
        }

        return (
          <Tag icon={<WarningOutlined />} color="error">
            Não
          </Tag>
        );
      },
      sorter: {
        compare: (a, b) => b.logged - a.logged,
        multiple: 1,
      },
    },
    {
      title: 'Aberto',
      dataIndex: 'opened_by_restaurant',
      key: 'opened_by_restaurant',
      align: 'left',
      render: (_, record) => {
        if (record.opened_by_restaurant) {
          return (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Sim
            </Tag>
          );
        }

        if (!record.opened && !record.opened_by_restaurant) {
          return (
            <Tag icon={<HourglassOutlined />} color="warning">
              Não
            </Tag>
          );
        }

        return (
          <Tag icon={<WarningOutlined />} color="error">
            Não
          </Tag>
        );
      },
      sorter: {
        compare: (a, b) => b.opened_by_restaurant - a.opened_by_restaurant,
        multiple: 1,
      },
    },
  ];

  if (restaurants.length === 0) {
    return (
      <Result
        status="404"
        title="Nenhum restaurante encontrado!"
        subTitle="Nenhum restaurante foi encontrado nessa situação!"
      />
    );
  }

  return (
    <Table
      ellipsis
      rowKey="id"
      scroll={{ y: 500 }}
      columns={columns}
      dataSource={restaurants}
    />
  );
};

List.propTypes = {
  restaurants: PropTypes.arrayOf(PropTypes.object),

  // eslint-disable-next-line react/no-unused-prop-types
  setSelectedKeys: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  selectedKeys: PropTypes.arrayOf(PropTypes.number),
  // eslint-disable-next-line react/no-unused-prop-types
  confirm: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  clearFilters: PropTypes.func,
};

List.defaultProps = {
  restaurants: [],

  selectedKeys: [],
  setSelectedKeys: () => {},
  confirm: () => {},
  clearFilters: () => {},
};

export default List;
