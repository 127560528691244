import styled from 'styled-components';
import { Layout, Col, Row, Button } from 'antd';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';

export const Wrapper = styled(Layout)`
  width: 100%;
  position: absolute;
  background-color: #f1f1f1;
  justify-content: center;
  align-items: center;
  padding-top 20px;
  padding-bottom 20px;
  font-family: 'Jost', sans-serif;
  height: 100%;
  min-height: 100%;
  overflow: auto;
`;

export const LoginBox = styled(Col)`
  background-color: white;
  border-radius: 0.286rem;
  font-weight: 400;
  line-height: 1.8;
  color: #212120;
  min-width: 500px;

  ${media.greaterThan('small')`
    padding: 90px 90px 40px 90px;
  `}

  ${media.lessThan('small')`
    padding: 30px;
    overflow: auto;
  `}

  ${media.lessThan('medium')`
    min-width: auto;
  `}
`;

export const RowText = styled(Row)`
  color: #D50032;
`;

export const RowLink = styled(Link)`
  color: #a39665;

  :hover {
    color: #a39665;
  }
`;

export const LoginButton = styled(Button)`
  background-color: #D50032;
  border-color: #D50032;
  font-weight: bold;

  :hover {
    background-color: #8c0525;
    border-color: #8c0525;
  }
`;
