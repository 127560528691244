/* Order status */
export const STATUS_CHECKIN = 1;
export const STATUS_CREATED = 2;
export const STATUS_CONFIRMED = 3;
export const STATUS_READY_FOR_PICKUP = 4;
export const STATUS_DISPATCHED = 5;
export const STATUS_CONCLUDED = 6;
export const STATUS_CANCELLED = 7;
export const STATUS_REJECTED = 8;

/* Delivery types */
export const TYPE_TAKEOUT = 1;
export const TYPE_DELIVERY = 2;

/* Delivery partner */
export const DELIVERY_PARTNER_OWN = 1;
export const DELIVERY_PARTNER_LALAMOVE = 3;

/* Handler users */
export const HANDLER_SYSTEM = 1;
export const HANDLER_RESTAURANT = 2;
export const HANDLER_CONSUMER = 3;

export const STATUS_DESCRIPTIONS = {
  2: "Aguardando confirmação do restaurante",
  3: "Em preparação",
  4: "Pronto Para retirada",
  5: "Entregador em rota",
  6: "Pedido finalizado",
  7: "Cancelado",
  8: "Rejeitado"
};

export const HANDLER_DESCRIPTIONS = {
  1: "Automático",
  2: "Restaurante",
  3: "Consumidor"
}

export const STATUS_DESCRIPTIONS_FOR_TABS = {
  waitingConfirmation: "Aguardando confirmação",
  waitingDelivery: "Aguardando envio",
  deliveryRoute: "Em Rota",
  deliveried: "Finalizados",
  canceled: "Cancelados",
};

export const DELIVERY_SERVICE_DESCRIPTIONS = {
  3: "Lalamove",
};
