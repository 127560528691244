import styled from 'styled-components';
import { Layout, Tabs, Col } from 'antd';

export const Wrapper = styled(Layout)`
  padding: 30px;
  flex: 1;
  overflow: auto;
  height: calc(100vh - 24px - 40px - 40px);
`;

export const TabPainel = styled(Tabs)`
  .ant-tabs-bar {
    padding-top: 20px;
    padding-left: 35px;
    margin-bottom: 0px;
  }
`;

export const Content = styled(Col)`
  .ant-card-body {
    padding: 3px;
  }
`;
