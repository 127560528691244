import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';

export const restaurants = [
  {
    title: 'Estabelecimento',
    dataIndex: 'name',
    key: 'client_name',
    width: 200,
    render: (name) => (
      <>
        <UserOutlined />
        &nbsp;
        <span style={{ fontWeight: 500, color: 'black' }}>{name}</span>
      </>
    ),
  },
  {
    title: 'Dados do Estabelecimento',
    dataIndex: 'delivery',
    key: 'delivery',
    width: 280,
    render: (delivery, data) => (
      <div>
        <Tag color="magenta" style={{ borderRadius: 10, zIndex: 99 }}>
          Cadastrado em:
          {' '}
          {moment(data.created_at).format('DD/MM/YYYY HH:mm')}
        </Tag> <br></br>

        <Tag color="magenta" style={{ borderRadius: 10, zIndex: 99 }}>
          Cadastrado há
          {' '}
          {moment().diff(moment(data.created_at), 'days') + 1}
          {' '}
          dias.
        </Tag> <br></br>

        {data.phone && (
          <Tag color="geekblue" style={{ borderRadius: 10 }}>
            Telefone:
            {' '}
            {data.phone}
          </Tag>)} 
          
        {data.phone && (<br></br>)}

        {data.whatsapp && (
          <Tag color="geekblue" style={{ borderRadius: 10 }}>
            Whatsapp:
            {' '}
            {data.whatsapp}
          </Tag>
        )}

        {data.whatsapp && (<br></br>)}

        {data.cnpj && (
          <Tag color="geekblue" style={{ borderRadius: 10 }}>
            CNPJ:
            {' '}
            {data.cnpj}
          </Tag>
        )}
      </div>
    ),
  },
  {
    title: 'Endereço',
    dataIndex: 'data',
    key: 'address',
    width: 230,
    render: (_, data) => (
      <Tag style={{ borderRadius: 10 }}>
        {data.address.street}
        ,
        {data.address.number}
        {' '}
        <br />
        {data.address.complement || 'Sem Complemento'}
        {' '}
        -
        {' '}
        {data.address.neighborhood}
        <br />
        CEP:
        {' '}
        {data.address.zipcode}
        {' '}
        <br />
        {data.address.city}
        /
        {data.address.state}
      </Tag>
    ),
  },
  {
    title: 'Informações',
    dataIndex: 'active_backoffice',
    key: 'active',
    width: 150,
    render: (active, data) => (
      <>
        <span style={{ fontWeight: 500, color: 'black' }}>{active ? 'Ativo' : 'Não Ativo'}</span><br></br>
        <span style={{ fontWeight: 500, color: 'black' }}>{data.visible_app && data.visible_app_backoffice ? 'Visivel no App' : 'Não Visivel no App'}</span>
      </>
    ),
  },
];
