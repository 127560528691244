import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import api from '../../services/api';

import { Creators as ReasonActions } from '../ducks/reasons';
import { Creators as QuestionActions } from '../ducks/questions';

export function* getReasons() {
  yield put(ReasonActions.setLoading(true));

  try {
    const { data, status } = yield call(
      api.get,
      `/reasons`,
    );

    if (status === 200) {
      yield put(ReasonActions.setReasons(data.data));
    }
  } catch (error) {
    message.error('Houve um erro ao retornar os motivos, tente novamente mais tarde!');
    yield put(ReasonActions.setReasons([]));
  }

  yield put(ReasonActions.setLoading(false));
}

export function* createReason({ payload }) {
  yield put(ReasonActions.setLoading(true));

  try {
    const { status } = yield call(api.post, '/reasons', payload);

    if (status === 200) {
      message.success(`O motivo '${payload.title}' foi criado com sucesso!`);

      yield put(ReasonActions.getReasons());
      yield put(ReasonActions.handleFormModalVisibility(false));
    }
  } catch (error) {
    message.error('Houve um erro ao criar o motivo, tente novamente mais tarde!');
  }

  yield put(ReasonActions.setLoading(false));
}

export function* editReason({ payload }) {
  yield put(ReasonActions.setLoading(true));

  try {
    const { status } = yield call(api.put, `/reasons/${payload.id}`, payload);

    if (status === 204) {
      message.success('O motivo foi alterado com sucesso!');

      yield put(ReasonActions.getReasons());
      yield put(ReasonActions.handleFormModalVisibility(false));
    }
  } catch (error) {
    message.error('Houve um erro ao editar o motivo, tente novamente mais tarde!');
  }

  yield put(ReasonActions.setLoading(false));
}

export function* deleteReason({ payload }) {
  yield put(ReasonActions.setLoading(true));

  try {
    const { status } = yield call(api.delete, `/reasons/${payload.id}`);

    if (status === 204) {
      message.success(`O motivo "${payload.title}" foi excluído com sucesso!`);

      yield put(ReasonActions.getReasons());
      yield put(QuestionActions.getQuestions());
    }
  } catch (error) {
    message.error('Houve um erro ao excluir o motivo, tente novamente mais tarde!');
  }

  yield put(ReasonActions.setLoading(false));
}
