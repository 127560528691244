import styled from 'styled-components';
import * as Antd from 'antd';

export const Table = styled(Antd.Table)`
  .ant-table {
    margin: 0px !important;
  }

  .ant-table-small .ant-table-thead > tr > th {
    background-color: #fafafa;
  }
`;
