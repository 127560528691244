import { call, put } from 'redux-saga/effects';
import { message } from 'antd';

import api from '../../services/api';
import { Creators as AuthActions } from '../ducks/auth';
import { Creators as UserActions } from '../ducks/me';

export function* login({ payload }) {
  try {
    const response = yield call(api.post, '/login', payload);

    if (response.status === 200) {
      yield localStorage.setItem('access_token', response.data.access_token);
      const { data, status } = yield call(api.get, '/auth/me');

      yield put(AuthActions.getAuthSuccess());

      if (status === 200) {
        yield put(UserActions.getAuthUserSuccess(data));
      }
    }
  } catch (error) {
    if (error.response) {
      message.error(
        error.response.status === 404 ? 'Usuário ou senha inválidos!' : 'Erro ao realizar login!',
      );
    } else {
      message.error('Erro ao realizar login!');
    }

    yield put(AuthActions.getAuthFailed('O usuário informado não existe'));
  }
}

export function* resetPassword({ payload }) {
  try {
    const { status } = yield call(api.post, '/reset-password', payload);
    if (status === 204) {
      message.success('Por favor, verifique sua caixa de e-mail para conferir sua nova senha!');
      yield put(AuthActions.resetPasswordSuccess());
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error('Erro ao enviar o e-mail, tente novamente mais tarde!');
    }
    yield put(AuthActions.resetPasswordFailed());
  }
}

export function* changePassword({ payload }) {
  try {
    const { status } = yield call(api.post, `users/${payload.id}/change-password`, payload);

    if (status === 204) {
      message.success('Alteraçao de senha realizado com sucesso!');
      yield put(AuthActions.changePasswordSuccess());
      yield put(UserActions.showChangePasswordModal(false));
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error('Erro ao alterar senha, tente novamente!');
    }
    yield put(AuthActions.changePasswordFailed());
  }
}

export function* logout() {
  try {
    const { status } = yield call(api.post, '/logout');

    if (status === 204) {
      localStorage.clear();
      yield put(UserActions.removeAuthUserSuccess());
      yield localStorage.setItem('access_token', null);
    }
  } catch (error) {
    message.error('Erro ao efetuar logout!');
  }
}
