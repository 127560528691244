import styled, { css } from 'styled-components';
import { Layout } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import media from 'styled-media-query';

const { Content } = Layout;

const { Header } = Layout;

export const Logo = styled.div`
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin-right: 60px;
  float: left;
`;

export const Wrapper = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  ${media.lessThan('small')`
      padding: 10px;
  `}
`;

export const Navbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lessThan('small')`
    display: none;
  `}
`;

export const MenuIconOpen = styled(MenuOutlined)`
  ${media.greaterThan('small')`
    display: none;
  `}
  color: white;
  font-size: 20px;
  margin-left: 20px;
`;

export const MenuIconClose = styled(CloseOutlined)`
  ${media.greaterThan('small')`
    display: none;
  `}
  color: white;
  font-size: 20px;
  margin-left: 20px;
`;

export const MenuMobile = styled.div`
  position: fixed;
  margin-top: 64px;
  display: flex;
  z-index: 5;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 21, 41, 0.97);

  div {
    color: white;
    margin: 30px;
    font-size: 20px;
  }
`;

export const Container = styled(Content)`
  overflow: hidden;

  ${({ mobileIsOpen }) =>
    !mobileIsOpen &&
    css`
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
  `}
`;
