import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Form, Row, Col, Divider } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';

import { Creators as AuthCreators } from '../../store/ducks/auth';
import { Wrapper, LoginBox, RowLink, LoginButton } from './styles';

function Login() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const onFinish = (values) => {
    dispatch(AuthCreators.getAuth(values));
  };

  return (
    <Wrapper>
      <Row>
        <LoginBox>
          <Row justify="center" style={{ paddingTop: 10 }}>
            <img
              src="assets/icons/getin_logo.svg"
              alt="Get In Delivery"
              width="150"
            />
          </Row>
          <Divider />
          <Col>
            <Form
              name="getin"
              initialValues={{
                remember: false,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'O campo e-mail é obrigatório!',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Usuário"
                  prefix={<MailOutlined style={{ marginRight: 10 }} />}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'O campo senha é obrigatório!',
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="**********"
                  prefix={<LockOutlined style={{ marginRight: 10 }} />}
                />
              </Form.Item>

              <p>
                <RowLink to="/reset-password">Esqueci minha senha</RowLink>
              </p>

              <Divider />

              <Form.Item>
                <Row justify="center" style={{ marginTop: 10 }}>
                  <LoginButton loading={loading} type="primary" htmlType="submit">
                    Entrar
                  </LoginButton>
                </Row>
              </Form.Item>

            </Form>
          </Col>
        </LoginBox>
      </Row>
    </Wrapper>
  );
}

export default Login;
