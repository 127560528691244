import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import moment from 'moment';

import api from '../../services/api';
import { Creators as MonitoringActions } from '../ducks/monitoring';

import { findRestaurantSessions } from '../../firebase/session';

export function* getRestaurants({ payload: filter }) {
  try {
    const { data, status } = yield call(
      api.get,
      `/restaurants/monitoring?${filter}=true`,
    );

    if (status === 200) {
      const { data: restaurants } = data;

      if (restaurants.length > 0) {
        const sessions = yield call(() => findRestaurantSessions(
          restaurants.map((restaurant) => restaurant.id),
        ));

        if (sessions.length > 0) {
          restaurants.map((restaurant) => {
            const users = sessions.filter(
              (session) => session.restaurant_id === restaurant.id,
            );

            if (users.length > 0) {
              const format = moment.unix(users[0].signed_in_at.seconds).format(
                'DD/MM/YYYY [às] HH:mm',
              );

              restaurant.logged = true;
              restaurant.signed_in_at = `Entrou em ${format}`;
            }

            return restaurant;
          });
        }
      }

      yield put(MonitoringActions.getRestaurantsSuccess(restaurants));
    }
  } catch (error) {
    message.error('Houve um erro ao atualizar as informações do monitoramento!');
    yield put(MonitoringActions.getRestaurantsFailed());
  }
}
