import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Alert, Row } from 'antd';

import Map from './components/Map';
import DeliveryInfo from './components/DeliveryInfo';

import { Creators as DeliveryActions }
  from '../../../../../../store/ducks/deliveryService';

import * as Constants from '../../../../../../constants/orders';

const GeolocationModal = () => {
  const dispatch = useDispatch();

  const {
    visibleGeoLocation,
    geoLocation,
    geoLocationOrder,
  } = useSelector((state) => state.delivery);

  const [origin, setOrigin] = useState({});
  const [markers, setMarkers] = useState([]);
  const [destination, setDestination] = useState({});

  const [estimatedDelivery, setEstimatedDelivery] = useState({});
  const [estimatedLoading, setEstimatedLoading] = useState(false);
  const [trackingUrl, setTrackingUrl] = useState('');
  const [timeoutGeolocation, setTimeoutGeolocation] = useState(0);

  useEffect(() => {
    if (!geoLocation || geoLocation.length === 0) return;

    if (!geoLocation.latitude || !geoLocation.longitude) {
      return;
    }

    setOrigin({
      lat: parseFloat(geoLocation.latitude),
      lng: parseFloat(geoLocation.longitude),
    });

    setTrackingUrl(geoLocation.trackingUrl || '');
    dispatch(DeliveryActions.handleGeolocationVisibility(true));

    setTimeoutGeolocation(setTimeout(() => {
      dispatch(DeliveryActions.geoLocation(geoLocationOrder));
    }, 60000));
  }, [geoLocation, dispatch]);

  const googleApiUrl =
    `${process.env.REACT_APP_GOOGLE_MAP_URL}?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`;

  useEffect(() => {
    const positions = [];

    if (geoLocationOrder) {
      const { customer_address, restaurant } = geoLocationOrder;

      if (customer_address) {
        positions.push({
          position: {
            lat: parseFloat(customer_address.latitude),
            lng: parseFloat(customer_address.longitude),
          },
          icon: {
            url: 'assets/icons/marker_user.svg',
          },
        });
      }

      if (restaurant) {
        positions.push({
          position: {
            lat: parseFloat(restaurant.address_latitude),
            lng: parseFloat(restaurant.address_longitude),
          },
          icon: {
            url: 'assets/icons/marker_restaurant.svg',
          },
        });
      }

      const destiny = {
        lat: 0,
        lng: 0,
      }

      if (
        geoLocationOrder.status_id === Constants.STATUS_READY_FOR_PICKUP
      ) {
        destiny.lat = parseFloat(restaurant.address_latitude);
        destiny.lng = parseFloat(restaurant.address_longitude);
      } else {
        destiny.lat = parseFloat(customer_address.latitude);
        destiny.lng = parseFloat(customer_address.longitude);
      }

      setDestination(destiny);
    }

    setMarkers(positions);
  }, [geoLocationOrder]);

  const hasCoordinates = () => {
    const { lat, lng } = origin;

    return !(typeof lat === 'undefined'
      || typeof lng === 'undefined' || lat === 0 || lng === 0);
  };

  const handleCloseModal = () => {
    dispatch(DeliveryActions.resetGeoLocation());
    dispatch(DeliveryActions.setModalGeoLocationClosed(true));
    dispatch(DeliveryActions.handleGeolocationVisibility(false));
    setEstimatedDelivery(null);
    setEstimatedLoading(false);
    clearTimeout(timeoutGeolocation);
  };

  return (
    <Modal
      title="Localização do entregador"
      visible={visibleGeoLocation}
      okButtonProps={trackingUrl ? {} : { style: { display: 'none' } }}
      onOk={() => { window.open(trackingUrl, '_blank', 'noopener,noreferrer'); }}
      okText="Acompanhar pedido pelo site"
      onCancel={() => handleCloseModal()}
      cancelText="Fechar"
      width={800}
    >
      {hasCoordinates() && (
        <>
          <DeliveryInfo
            order={geoLocationOrder}
            loading={estimatedLoading}
            estimatedInfo={estimatedDelivery}
          />

          <Map
            googleMapURL={googleApiUrl}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            onLoadEstimatedTime={(value) => {
              setEstimatedDelivery(value);
              setEstimatedLoading(false);
            }}
            beforeLoadEstimatedTime={() => setEstimatedLoading(true)}
            origin={origin}
            destination={destination}
            markers={[
              {
                position: origin,
                icon: {
                  url: 'assets/icons/marker_delivery.svg',
                },
              },
              ...markers
            ]}
          />

          <Row style={{ marginTop: '5px' }}>
            <i>
              <span style={{ color: 'red' }}>*</span>
              {' '}
              <span style={{ fontSize: 12 }}>Atualização a cada 1 minuto</span>
            </i>
          </Row>
        </>
      )}

      {!hasCoordinates() && <Alert
        type="warning"
        message="Não foi possível carregar o mapa"
        description="Desculpe, mas não conseguimos renderizar o mapa para rastreio do pedido."
      />}
    </Modal>
  );
};

export default GeolocationModal;
