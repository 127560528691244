import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Layout, Menu, Dropdown, Button } from 'antd';

import {
  Wrapper,
  Navbar,
  MenuIconOpen,
  MenuMobile,
  MenuIconClose,
  Container,
} from './styles';

import { Creators as UserActions } from '../../store/ducks/me';
import ChangePassword from '../../pages/ChangePassword';

import {
  ROLE_MASTER,
  ROLE_SUPPORT,
  ROLE_MARKETING,
} from '../../constants/roles';

const navs = [
  {
    name: 'Restaurantes',
    path: '/restaurants',
    role: [
      ROLE_MASTER,
      ROLE_MARKETING,
    ],
  },
  {
    name: 'Monitoramento',
    path: '/monitoring',
    role: [
      ROLE_MASTER,
      ROLE_SUPPORT,
      ROLE_MARKETING,
    ],
  },
  {
    name: 'Cupons',
    path: '/coupons',
    role: [
      ROLE_MASTER,
      ROLE_MARKETING,
    ],
  },
  {
    name: 'Perguntas',
    path: '/questions',
    role: [
      ROLE_MASTER,
      ROLE_MARKETING,
    ],
  },
  {
    name: 'Usuários',
    path: '/users',
    role: [
      ROLE_MASTER,
    ],
  },
  {
    name: 'Configurações',
    path: '/settings',
    role: [
      ROLE_MASTER,
      ROLE_MARKETING,
      ROLE_SUPPORT,
    ],
  },
];

const Header = ({ children }) => {
  const [isOpen, setMenuIsOpen] = useState(false);
  const { pathname } = useLocation();
  const { user, showChangePasswordModal } = useSelector((state) => state.me);
  const dispatch = useDispatch();

  function logout() {
    dispatch(UserActions.removeAuthUser());
  }

  const changePassword = () => {
    dispatch(UserActions.showChangePasswordModal(true));
  };

  useEffect(() => {
    setMenuIsOpen(false);
  }, [pathname]);

  return user ? (
    <Layout>
      <Wrapper className="header" justify="space-between" breakpoint="sm">
        <Navbar>
          <Menu
            theme="dark"
            breakpoint="lg"
            mode="horizontal"
            defaultSelectedKeys={[pathname.substring(1) === '' ? 'restaurants' : pathname.substring(1)]}
          >
            {navs.map((nav) => {
              if (nav.role.find((role) => user.role_id === role)) {
                return (
                  <Menu.Item key={nav.path.substring(1)}>
                    <Link to={nav.path}>{nav.name}</Link>
                  </Menu.Item>
                );
              }

              return null;
            })}
          </Menu>
        </Navbar>

        {isMobile && isOpen ? (
          <div onClick={() => setMenuIsOpen(false)}>
            <MenuIconClose />
          </div>
        ) : (
          <div onClick={() => setMenuIsOpen(true)}>
            <MenuIconOpen />
          </div>
        )}

        <Dropdown
          trigger={['click', 'hover']}
          overlay={() => (
            <Menu>
              <Menu.Item onClick={() => changePassword()}>
                Alterar Senha
              </Menu.Item>
              <Menu.Item style={{ color: 'red' }} onClick={() => logout()}>
                Sair
              </Menu.Item>
            </Menu>
          )}
          placement="bottomLeft"
        >
          <Button style={{ background: 'none', color: 'white', border: 'none' }}>
            {`Olá, ${user.name}`}
          </Button>
        </Dropdown>
      </Wrapper>

      {isMobile && isOpen && (
        <MenuMobile>
          {navs.map((nav, key) => {
            if (nav.role.find((role) => user.role_id === role)) {
              return (
                <div key={key.toString()}>
                  <Link style={{ color: 'white' }} to={nav.path}>
                    {nav.name}
                  </Link>
                </div>
              );
            }

            return null;
          })}
        </MenuMobile>
      )}

      <Container className="site-layout" mobileIsOpen={isOpen}>
        <ChangePassword user={user} visible={showChangePasswordModal} />
        {children}
      </Container>
    </Layout>
  ) : (
    children
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Header;
