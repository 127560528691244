import Immutable from 'seamless-immutable';

export const Types = {
  GET_COUPONS_REQUEST: 'coupons/GET_COUPONS_REQUEST',
  GET_COUPONS_SUCCESS: 'coupons/GET_COUPONS_SUCCESS',
  GET_COUPONS_FAILED: 'coupons/GET_COUPONS_FAILED',

  GET_COUPON_REQUEST: 'coupons/GET_COUPON_REQUEST',
  GET_COUPON_SUCCESS: 'coupons/GET_COUPON_SUCCESS',
  GET_COUPON_FAILED: 'coupons/GET_COUPON_FAILED',

  CREATE_COUPONS_REQUEST: 'coupons/CREATE_COUPONS_REQUEST',
  CREATE_COUPONS_SUCCESS: 'coupons/CREATE_COUPONS_SUCCESS',
  CREATE_COUPONS_FAILED: 'coupons/CREATE_COUPONS_FAILED',

  EDIT_COUPON_REQUEST: 'coupons/EDIT_COUPON_REQUEST',
  EDIT_COUPON_SUCCESS: 'coupons/EDIT_COUPON_SUCCESS',
  EDIT_COUPON_FAILED: 'coupons/EDIT_COUPON_FAILED',

  DELETE_COUPON_REQUEST: 'coupons/DELETE_COUPON_REQUEST',
  DELETE_COUPON_SUCCESS: 'coupons/DELETE_COUPON_SUCCESS',
  DELETE_COUPON_FAILED: 'coupons/DELETE_COUPON_FAILED',

  HANDLE_MODAL_VISIBILITY: 'coupons/HANDLE_MODAL_VISIBILITY',
  RESET_COUPON: 'coupons/RESET_COUPON',
};

const INITIAL_STATE = Immutable({
  loading: false,
  uploading: null,
  saving: false,
  visible: false,
  data: [],
});

export default function coupons(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_COUPONS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_COUPONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }

    case Types.GET_COUPONS_FAILED: {
      return {
        ...state,
        loading: false,
        data: [],
      };
    }

    case Types.CREATE_COUPONS_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.CREATE_COUPONS_SUCCESS: {
      return {
        ...state,
        saving: false,
        data: [...state.data, action.payload],
      };
    }

    case Types.CREATE_COUPONS_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.EDIT_COUPON_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.EDIT_COUPON_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.EDIT_COUPON_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.DELETE_COUPON_SUCCESS: {
      return {
        ...state,
        data: state.data.filter((coupon) => coupon.id !== action.payload),
      };
    }

    case Types.GET_COUPON_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_COUPON_SUCCESS: {
      return {
        ...state,
        loading: false,
        coupon: action.payload,
      };
    }

    case Types.GET_COUPON_FAILED: {
      return {
        ...state,
        loading: false,
        coupon: {},
      };
    }

    case Types.HANDLE_MODAL_VISIBILITY: {
      return {
        ...state,
        visible: action.payload,
      };
    }

    case Types.RESET_COUPON: {
      return {
        ...state,
        coupon: {},
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getCoupons: (payload) => ({
    type: Types.GET_COUPONS_REQUEST,
    payload,
  }),

  getCouponsSuccess: (payload) => ({
    type: Types.GET_COUPONS_SUCCESS,
    payload,
  }),

  getCouponsFailed: (payload) => ({
    type: Types.GET_COUPONS_FAILED,
    payload,
  }),

  createCoupon: (payload) => ({
    type: Types.CREATE_COUPONS_REQUEST,
    payload,
  }),

  createCouponSuccess: (payload) => ({
    type: Types.CREATE_COUPONS_SUCCESS,
    payload,
  }),

  createCouponFailed: (payload) => ({
    type: Types.CREATE_COUPONS_FAILED,
    payload,
  }),

  editCoupon: (payload) => ({
    type: Types.EDIT_COUPON_REQUEST,
    payload,
  }),

  editCouponSuccess: (payload) => ({
    type: Types.EDIT_COUPON_SUCCESS,
    payload,
  }),

  editCouponFailed: (payload) => ({
    type: Types.EDIT_COUPON_FAILED,
    payload,
  }),

  deleteCoupon: (payload) => ({
    type: Types.DELETE_COUPON_REQUEST,
    payload,
  }),

  deleteCouponSuccess: (payload) => ({
    type: Types.DELETE_COUPON_SUCCESS,
    payload,
  }),

  deleteCouponFailed: (payload) => ({
    type: Types.DELETE_COUPON_FAILED,
    payload,
  }),

  getCoupon: (payload) => ({
    type: Types.GET_COUPON_REQUEST,
    payload,
  }),

  getCouponSuccess: (payload) => ({
    type: Types.GET_COUPON_SUCCESS,
    payload,
  }),

  getCouponFailed: (payload) => ({
    type: Types.GET_COUPON_FAILED,
    payload,
  }),

  handleModalVisibility: (payload) => ({
    type: Types.HANDLE_MODAL_VISIBILITY,
    payload,
  }),

  resetCoupon: () => ({
    type: Types.RESET_COUPON,
  }),
};
