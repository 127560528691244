import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import api from '../../services/api';
import { Creators as SettingActions } from '../ducks/settings';

export function* getSettings() {
  try {
    const { data } = yield call(api.get, '/settings');

    yield put(SettingActions.getSettingsSuccess(data));
  } catch (error) {
    message.error('Houve um erro ao retornar as configurações, tente novamente!');
    yield put(SettingActions.getSettingsFailed());
  }
}

export function* saveSettings({ payload }) {
  try {
    yield call(api.post, '/settings', payload);

    message.success('As configurações foram salvas com sucesso!');
    yield put(SettingActions.saveSettingsSuccess());
    yield put(SettingActions.getSettings());
  } catch (error) {
    let content = 'Houve um erro ao salvar as configurações, tente novamente mais tarde!';

    if (error.response) {
      const { response } = error;

      if (response?.data?.message) {
        content = response.data.message;
      }

      message.warn(content);
    } else {
      message.error(content);
    }

    yield put(SettingActions.saveSettingsFailed());
  }
}
