import Immutable from 'seamless-immutable';

export const Types = {
  GET_AUTH_REQUEST: 'auth/GET_AUTH_REQUEST',
  GET_AUTH_SUCCESS: 'auth/GET_AUTH_SUCCESS',
  GET_AUTH_FAILED: 'auth/GET_AUTH_FAILED',
  RESET_PASSWORD_REQUEST: 'auth/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'auth/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILED: 'auth/RESET_PASSWORD_FAILED',
  CHANGE_PASSWORD_REQUEST: 'auth/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'auth/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED: 'auth/CHANGE_PASSWORD_FAILED',
};

const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
});

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_AUTH_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.GET_AUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.GET_AUTH_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case Types.RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case Types.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case Types.CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case Types.CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getAuth: (payload) => ({
    type: Types.GET_AUTH_REQUEST,
    payload,
  }),
  getAuthSuccess: (payload) => ({
    type: Types.GET_AUTH_SUCCESS,
    payload,
  }),
  getAuthFailed: (payload) => ({
    type: Types.GET_AUTH_FAILED,
    payload,
  }),
  resetPassword: (payload) => ({
    type: Types.RESET_PASSWORD_REQUEST,
    payload,
  }),
  resetPasswordSuccess: (payload) => ({
    type: Types.RESET_PASSWORD_SUCCESS,
    payload,
  }),
  resetPasswordFailed: (payload) => ({
    type: Types.RESET_PASSWORD_FAILED,
    payload,
  }),
  changePassword: (payload) => ({
    type: Types.CHANGE_PASSWORD_REQUEST,
    payload,
  }),
  changePasswordSuccess: (payload) => ({
    type: Types.CHANGE_PASSWORD_SUCCESS,
    payload,
  }),
  changePasswordFailed: (payload) => ({
    type: Types.CHANGE_PASSWORD_FAILED,
    payload,
  }),
};
