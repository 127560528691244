export const IDENTIFICATION = 'identificacao';
export const RESIDENCE = 'residencia';
export const ACTIVITY = 'atividade';
export const ACTIVITY_2 = 'atividade2';
export const CNPJ = 'cnpj';
export const SOCIAL_CONTRACT = 'contrato_social';
export const PARTNER_DOCUMENT = 'documento_socio';

const labels = [];

labels[IDENTIFICATION] = 'Documento de Identificação';
labels[RESIDENCE] = 'Comprovante de residência';
labels[ACTIVITY] = 'Nota fiscal de compras';
labels[ACTIVITY_2] = 'Nota fiscal de compras';
labels[CNPJ] = 'Identificação CNPJ (Cartão CNPJ)';
labels[SOCIAL_CONTRACT] = 'Contrato Social ou Certificado MEI';
labels[PARTNER_DOCUMENT] = 'Documento do sócio (RG ou CNH)';

export const LABELS = labels;

export const STATUS_PENDING = 0;
export const STATUS_APPROVED = 1;
export const STATUS_DENIED = 2;
