import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row } from 'antd';

import { Wrapper, TabPainel } from './styles';
import * as Constants from '../../../../constants/orders';

import OrdersList from './components/OrdersList';
import GeolocationModal from './components/GeolocationModal';

import { Creators as OrderActions } from '../../../../store/ducks/orders';
import { Creators as RestaurantCreators } from '../../../../store/ducks/restaurants';

const { TabPane } = TabPainel;

const Orders = () => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState('');

  const orders = useSelector((state) => state.orders);
  const { data: restaurant } = useSelector((state) => state.restaurants);
  const { placeDeliveryOrder } = useSelector((state) => state.delivery);

  useEffect(() => {
    if (!restaurant) {
      dispatch(RestaurantCreators.getRestaurant());
    }

    dispatch(OrderActions.getOrders());
  }, [dispatch, restaurant]);

  useEffect(() => {
    dispatch(OrderActions.getOrders());
  }, [dispatch, placeDeliveryOrder]);

  useEffect(() => {
    Object.keys(Constants.STATUS_DESCRIPTIONS_FOR_TABS).map((key) => {
      const element = document.body.querySelector(`[aria-controls*='panel-${key}']`);

      if (element) {
        element.setAttribute('data-count', orders[key].length);
      }
    });
  }, [orders]);

  return (
    <>
      <TabPainel
        style={{ marginLeft: '30px' }}
        animated={false}
        defaultActiveKey={tab}
        onChange={(key) => setTab(key)}
      >
        {Object.keys(Constants.STATUS_DESCRIPTIONS_FOR_TABS).map((key) => (
          <TabPane
            forceRender
            tab={`${Constants.STATUS_DESCRIPTIONS_FOR_TABS[key]} (${orders[key].length})`}
            key={key}
          >
            <Wrapper>
              <Row justify="space-between">
                <OrdersList status={key} />
              </Row>
            </Wrapper>
          </TabPane>
        ))}
      </TabPainel>

      <GeolocationModal />
    </>
  );
};

export default Orders;
