import React, { useState } from 'react';
import { Row } from 'antd';

import { Wrapper, TabPainel } from './styles';

import NewRestaurants from './components/New';
import EnabledRestaurants from './components/Enabled';

const { TabPane } = TabPainel;

const Restaurants = () => {
  const [tab, setTab] = useState('1');

  return (
    <TabPainel animated={false} defaultActiveKey={tab} onChange={(key) => setTab(key)}>
      <TabPane tab="Novos" key="1">
        <Wrapper>
          <Row justify="space-between">
            <NewRestaurants />
          </Row>
        </Wrapper>
      </TabPane>
      <TabPane tab="Concluídos" key="6">
        <Wrapper>
          <Row justify="space-between">
            <EnabledRestaurants />
          </Row>
        </Wrapper>
      </TabPane>
    </TabPainel>
  );
};

export default Restaurants;
