import React from 'react';

import {
  Button,
  Table,
  Space,
  Input,
  Modal,
} from 'antd';

import {
  EditOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

const { confirm } = Modal;

const List = ({
  reasons,
  handleDelete,
  handleEdit,
}) => {
  const deleteQuestion = (question) => {
    confirm({
      content: `Você deseja realmente excluir o motivo "${question.title}"?`,
      icon: <ExclamationCircleOutlined />,
      title: 'Solicitação para excluir motivo',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk() {
        handleDelete({
          id: question.id,
          title: question.title,
        });
      },
    });
  };

  const getColumnSearchProps = (dataIndex, placeholder, searchInput) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Procurar
        </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
            icon={<CloseCircleOutlined />}
          >
            Limpar
        </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: 'Motivo',
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      sorter: {
        compare: (a, b) => {
          const titleA = a.title.toUpperCase();
          const titleB = b.title.toUpperCase();

          if (titleA < titleB) {
            return -1;
          }

          if (titleA > titleB) {
            return 1;
          }

          return 0;
        },
        multiple: 1,
      },
      ...getColumnSearchProps('title', 'Pesquisar', {}),
    },
    {
      title: 'Ações',
      dataIndex: '',
      key: 'actions',
      align: 'center',
      render: (_, record) => (
        <>
          <Button
            onClick={() => {
              handleEdit(record);
            }}
            type="primary"
            size="small"
            style={{
              borderRadius: '5px',
              marginRight: '5px',
            }}
            icon={<EditOutlined />}
          >
            Editar
          </Button>

          <Button
            onClick={() => deleteQuestion(record)}
            type="danger"
            size="small"
            style={{ borderRadius: '5px' }}
            icon={<CloseCircleOutlined />}
          >
            Excluir
          </Button>
        </>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      ellipsis
      scroll={{ y: 500 }}
      rowKey="id"
      columns={columns}
      dataSource={reasons}
    />
  );
};

export default List;
