import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Wrapper, TabPainel } from './styles';
import CouponsList from './components/CouponsList';

const { TabPane } = TabPainel;

const Coupon = () => {
  
  const [tab, setTab] = useState('1');

  return (
    <TabPainel animated={false} defaultActiveKey={tab} onChange={(key) => setTab(key)}>
      <TabPane tab="Vouchers" key="1">
        <Wrapper>
          <CouponsList />
        </Wrapper>
      </TabPane>
    </TabPainel>
  );
};
export default Coupon;
