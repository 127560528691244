import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Input, Form, Typography, Row, Col, Divider } from 'antd';
import { Creators as AuthCreators } from '../../store/ducks/auth';

import { Wrapper, LoginBox, LoginButton, RowLink } from './styles';

const { Title } = Typography;

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading } = useSelector((state) => state.auth);

  const onFinish = (values) => {
    dispatch(
      AuthCreators.resetPassword({
        ...values,
      }),
    );
  };

  return (
    <Wrapper>
      <Row>
        <LoginBox span={24}>
        <Row justify="center" style={{ paddingTop: 10 }}>
            <img
              src="assets/icons/getin_logo.svg"
              alt="Get In Delivery"
              width="150"
            />
          </Row>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            <Row justify="center" style={{ paddingTop: 20, paddingBottom: 10 }}>
              <Col>
                <Title level={3}>Recuperar Senha</Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="email"
                  label="E-mail do Responsável"
                  rules={[{ required: true, message: 'O e-mail do responsável é obrigatório!' }]}
                >
                  <Input size="large" placeholder="E-mail indicado no momento do cadastro" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Divider style={{ marginTop: 0 }} />
          <Row justify="center">
            <Col span={24}>
              <LoginButton
                loading={loading}
                onClick={() => form.submit()}
                type="primary"
                block
              >
                Enviar
              </LoginButton>
            </Col>
            <Divider />
            <RowLink to="/login">Clique aqui para retornar ao login.</RowLink>
          </Row>
        </LoginBox>
      </Row>
    </Wrapper>
  );
};

export default ResetPassword;
