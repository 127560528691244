import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { useDispatch, useSelector } from 'react-redux';
import { Layout, Alert, Typography, Switch, Row, Col, Form, Button } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import InputGroup from '../../../components/InputGroup';
import { Creators as SettingsCreators } from '../../../store/ducks/settings';

const { Title } = Typography;

const Content = styled(Layout.Content)`
  overflow: auto;
  max-height: 55rem;
  background-color: white;
  padding: 0px 20px;
  .ant-alert-icon {
    margin-top: 10px;
  }

  ${media.between('large', 'huge')`
    max-height: 29rem;
  `}

  ${media.greaterThan('1800px')`
    max-height: 40rem;
  `}

  ${media.lessThan('small')`
    max-height: 30rem;
  `}
`;

const AlertContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const Delivery = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [freightMargin, setFreightMargin] = useState(true);
  const [customerDeliveryFee, setCustomerDeliveryFee] = useState(false);

  const [activeTip, setActiveTip] = useState(false);
  const [minValueTip, setMinValueTip] = useState(4);
  const [maxValueTip, setMaxValueTip] = useState(4);

  const { data: settings, saving } = useSelector((state) => state.settings);

  useEffect(() => {
    if (settings) {
      setFreightMargin(settings.freight_refund > 0);
      setCustomerDeliveryFee(settings.customer_delivery_fee > 0);

      setActiveTip(settings.active_tip);
      setMinValueTip(settings.min_tip || 4);
      setMaxValueTip(settings.max_tip || 4);

      form.setFieldsValue(settings);
    }
  }, [settings, form]);

  function getFieldBoolean(value) {
    return value ? 'success' : 'error';
  }

  const onFinish = (values) => {
    const formValues = values;

    formValues.active_tip = activeTip;
    formValues.max_tip = maxValueTip;
    formValues.min_tip = minValueTip;

    if (!activeTip) {
      formValues.max_tip = 0;
      formValues.min_tip = 0;
    }

    if (!freightMargin) {
      formValues.freight_refund = 0;
    }

    if (!customerDeliveryFee) {
      formValues.customer_delivery_fee = 0;
    }

    dispatch(SettingsCreators.saveSettings(formValues));
  };

  return (
    <>
      <Content>
        <Form form={form} onFinish={onFinish}>
          <Row>
            <Col span={24}>
              <Alert
                message={<Title level={2}>% de margem em cima do frete</Title>}
                description={
                  <AlertContainer>
                    <div>
                      <p>
                        Representa a porcentagem da margem cobrada ou descontada em cima do valor do frete
                        <br></br>
                        Máximo de 100% e mínimo de -100%
                      </p>
                        <Form.Item name="freight_margin">
                          <InputGroup prefix="%" max={100} min={-100} />
                        </Form.Item>
                    </div>
                    <div>
                      <Switch
                        style={{ marginTop: -35 }}
                        onChange={(checked) => setFreightMargin(checked)}
                        checked={true}
                        disabled={true}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </div>
                  </AlertContainer>
                }
                type="success"
                showIcon
              />
            </Col>
          </Row>
        </Form>
      </Content>

      <div
        style={{
          display: 'flex',
          flex: 1,
          paddingTop: 15,
          paddingBottom: 15,
          paddingRight: 20,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={saving}
          onClick={() => form.submit()}
        >
          Salvar
        </Button>
      </div>
    </>
  );
};

export default Delivery;
