import Immutable from 'seamless-immutable';

export const Types = {
  GET_ALL_USERS_REQUEST: 'user/GET_ALL_USERS_REQUEST',
  GET_ALL_USERS_SUCCESS: 'user/GET_ALL_USERS_SUCCESS',
  GET_ALL_USERS_FAILED: 'user/GET_ALL_USERS_FAILED',

  CREATE_USER_REQUEST: 'user/CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'user/CREATE_USER_SUCCESS',
  CREATE_USER_FAILED: 'user/CREATE_USER_FAILED',

  EDIT_USER_REQUEST: 'user/EDIT_USER_REQUEST',
  EDIT_USER_SUCCESS: 'user/EDIT_USER_SUCCESS',
  EDIT_USER_FAILED: 'user/EDIT_USER_FAILED',

  TOGGLE_USER_VISIBILITY_REQUEST: 'user/TOGGLE_USER_VISIBILITY_REQUEST',
  TOGGLE_USER_VISIBILITY_SUCCESS: 'user/TOGGLE_USER_VISIBILITY_SUCCESS',

  HANDLE_MODAL_VISIBILITY: 'user/HANDLE_MODAL_VISIBILITY',
};

const INITIAL_STATE = Immutable({
  data: [],
  visible: false,
  loading: false,
  saving: false,
});

export default function users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_ALL_USERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }

    case Types.CREATE_USER_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.CREATE_USER_SUCCESS: {
      return {
        ...state,
        saving: false,
        data: [...state.data, action.payload],
        visible: false,
      };
    }

    case Types.EDIT_USER_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.EDIT_USER_SUCCESS: {
      return {
        ...state,
        saving: false,
        visible: false,
      };
    }

    case Types.TOGGLE_USER_VISIBILITY_SUCCESS: {
      return {
        ...state,
        data: state.data.map((user) => {
          if (user.id === action.payload.id) {
            return {
              ...user,
              active: action.payload.active,
            };
          }

          return user;
        }),
      };
    }

    case Types.CREATE_USER_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.EDIT_USER_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.HANDLE_MODAL_VISIBILITY: {
      return {
        ...state,
        visible: action.payload,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getAllUsers: (payload) => ({
    type: Types.GET_ALL_USERS_REQUEST,
    payload,
  }),

  getAllUsersSuccess: (payload) => ({
    type: Types.GET_ALL_USERS_SUCCESS,
    payload,
  }),

  getAllUsersFailed: (payload) => ({
    type: Types.GET_ALL_USERS_FAILED,
    payload,
  }),

  createUser: (payload) => ({
    type: Types.CREATE_USER_REQUEST,
    payload,
  }),

  createUserSuccess: (payload) => ({
    type: Types.CREATE_USER_SUCCESS,
    payload,
  }),

  createUserFailed: () => ({
    type: Types.CREATE_USER_FAILED,
  }),

  editUser: (payload) => ({
    type: Types.EDIT_USER_REQUEST,
    payload,
  }),

  editUserSuccess: () => ({
    type: Types.EDIT_USER_SUCCESS,
  }),

  editUserFailed: () => ({
    type: Types.EDIT_USER_FAILED,
  }),

  toggleVisibility: (payload) => ({
    type: Types.TOGGLE_USER_VISIBILITY_REQUEST,
    payload,
  }),

  toggleVisibilitySuccess: (payload) => ({
    type: Types.TOGGLE_USER_VISIBILITY_SUCCESS,
    payload,
  }),

  handleModalVisibility: (payload) => ({
    type: Types.HANDLE_MODAL_VISIBILITY,
    payload,
  }),
};
