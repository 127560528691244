import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as OrderActions } from '../../store/ducks/orders';
import { isAuthenticated } from '../../configs/utils';

const SoundNotification = () => {
  const dispatch = useDispatch();
  const [canNotify, setCanNotify] = useState(false);

  const {
    waitingConfirmation,
  } = useSelector((state) => state.orders);
  const { user } = useSelector((state) => state.me);

  const timeInterval = process.env.REACT_APP_NOTIFICATION_TIME_INTERVAL || 30000;
  const waitingConfirmationSound = new Audio('/assets/sounds/new-order.mp3');

  function runNotification() {
    if (waitingConfirmation.length > 0) {
      waitingConfirmationSound.play();
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isAuthenticated(user)) {
        setCanNotify(true);
        dispatch(OrderActions.getOrders());
      }
    }, timeInterval);

    return () => clearInterval(interval);
  }, [user]);

  useEffect(() => {
    if (canNotify) {
      setCanNotify(false);
      runNotification();
    }
  }, [waitingConfirmation]);

  return (null);
};

export default SoundNotification;
