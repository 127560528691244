import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as ReasonCreators } from '../../store/ducks/reasons';

import {
  Modal,
  Button,
  Divider,
} from 'antd';

import {
  PlusCircleOutlined,
} from '@ant-design/icons';

import List from './components/List';
import Form from './components/Form';

const Reason = () => {
  const dispatch = useDispatch();

  const [editableReason, setEditableReason] = useState(null);

  const {
    reasons,
    visible,
    formVisible,
    saving,
  } = useSelector((state) => state.reasons);

  useEffect(() => {
    dispatch(ReasonCreators.getReasons());
  }, []);

  const handleModal = (status) => {
    dispatch(ReasonCreators.handleModalVisibility(status));
  };

  const handleFormModal = (status) => {
    dispatch(ReasonCreators.handleFormModalVisibility(status));
  };

  return (
    <>
      <Form
        visible={formVisible}
        reason={editableReason}
        saving={saving}
        handleSave={(values) => dispatch(ReasonCreators.createReason(values))}
        handleEdit={(values) => dispatch(ReasonCreators.editReason(values))}
        handleModal={handleFormModal}
      />

      <Modal
        title="Motivos"
        maskClosable={false}
        centered
        width={700}
        visible={visible}
        onCancel={() => handleModal(false)}
        footer={[
          <Button
            key="back"
            onClick={() => handleModal(false)}
          >
            Fechar
          </Button>,
        ]}
      >
        <Button
          onClick={() => {
            setEditableReason(null);
            handleFormModal(true);
          }}
          style={{
            float: 'right',
            marginBottom: '10px',
            borderRadius: 20,
          }}
          type="primary"
          icon={<PlusCircleOutlined />}
        >
          Adicionar motivo
        </Button>
        <Divider />
        <List
          reasons={reasons}
          handleModal={handleModal}
          handleDelete={(reason) =>
            dispatch(ReasonCreators.deleteReason(reason))
          }
          handleEdit={(reason) => {
            setEditableReason(reason);
            handleFormModal(true);
          }}
        />
      </Modal>
    </>
  );
};

export default Reason;
