import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Result,
  Skeleton,
  Table,
  Button,
  Tag,
  Modal,
} from 'antd';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { Content } from './styles';

const { confirm } = Modal;

const QuestionList = ({
  questions,
  loading,
  handleEditQuestion,
  handleDelete,
  handleModal,
}) => {
  const dispatch = useDispatch();

  const getListStyle = () => ({
    width: '100%',
    background: '#f2f2f2',
    padding: 8,
    margin: 0,
  });

  const deleteQuestion = (question) => {
    confirm({
      content: `Você deseja realmente excluir a pergunta "${question.title}"?`,
      icon: <ExclamationCircleOutlined />,
      title: 'Solicitação para excluir pergunta',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk() {
        handleDelete({
          id: question.id,
          title: question.title,
        });
      },
    });
  };

  const columns = [
    {
      title: 'Pergunta',
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      sorter: {
        compare: (a, b) => {
          const titleA = a.title.toUpperCase();
          const titleB = b.title.toUpperCase();

          if (titleA < titleB) {
            return -1;
          }

          if (titleA > titleB) {
            return 1;
          }

          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      align: 'left',
      render: (active) => (
        <>
          {active ? (
            <Tag
              icon={<CheckCircleOutlined />}
              color="success"
            >
              Ativo
            </Tag>
          ) : (
            <Tag
              icon={<MinusCircleOutlined />}
              color="default"
            >
              Desativado
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Ações',
      dataIndex: '',
      key: 'actions',
      align: 'center',
      render: (_, record) => (
        <>
          <Button
            onClick={() => {
              handleEditQuestion(record.id);
              handleModal(true);
            }}
            type="primary"
            size="small"
            style={{
              borderRadius: '5px',
              marginRight: '5px',
            }}
            icon={<EditOutlined />}
          >
            Editar
          </Button>

          <Button
            onClick={() => deleteQuestion(record)}
            type="danger"
            size="small"
            style={{ borderRadius: '5px' }}
            icon={<CloseCircleOutlined />}
          >
            Excluir
          </Button>
        </>
      ),
    },
  ];

  if (questions.length === 0 && !loading) {
    return (
      <Result
        status="404"
        title={'Nenhuma pergunta cadastrada!'}
        subTitle={
          'Desculpe! A lista está vazia, tente cadastrar uma pergunta.'
        }
      />
    );
  }

  return (
    <Content>
      {loading ? (
        <div style={getListStyle()}>
          {Array.from([0, 1, 2, 3, 4, 5]).map((index) => (
            <Skeleton key={index} avatar active />
          ))}
        </div>
      ) : (
        <Table
          ellipsis
          rowKey="id"
          scroll={{ y: 500 }}
          columns={columns}
          dataSource={questions}
        />
      )}
    </Content>
  );
};

export default QuestionList;
