import React, { useState, memo } from 'react';

import {
  Select,
  Row,
  Col,
  Button,
} from 'antd';

import {
  SearchOutlined,
  HourglassOutlined,
  ShopOutlined,
} from '@ant-design/icons';

import {
  FILTER_SHOULD_BE_OPEN,
  FILTER_CLOSE_TO_OPEN,
} from '../../../../../../constants/monitoring';

const Filter = ({
  onChange,
  loading,
}) => {
  const [filter, setFilter] = useState(FILTER_SHOULD_BE_OPEN);

  return (
    <Row style={{ marginBottom: 15 }}>
      <Col sm={24} md={24} lg={24}>
        <strong>Situação dos restaurantes:</strong>
        <Select
          style={{
            marginLeft: 5,
            minWidth: 210,
          }}
          onChange={(value) => setFilter(value)}
          placeholder="Selecione um filtro"
          value={filter}
        >
          <Select.Option value={FILTER_SHOULD_BE_OPEN}>
            <ShopOutlined style={{ marginRight: 5 }} />
            Deveriam estar abertos
          </Select.Option>

          <Select.Option value={FILTER_CLOSE_TO_OPEN}>
            <HourglassOutlined style={{ marginRight: 5 }} />
            Próximos de abrirem
          </Select.Option>
        </Select>

        <Button
          style={{
            marginLeft: 10,
          }}
          loading={loading}
          disabled={loading}
          type="primary"
          onClick={() => {
            onChange(filter);
          }}
          icon={<SearchOutlined />}
        >
          {loading ? 'Atualizando' : 'Filtrar'}
        </Button>
      </Col>
    </Row>
  );
}

export default memo(Filter);
