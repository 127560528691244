import { call, put, select } from 'redux-saga/effects';
import { message } from 'antd';

import api from '../../services/api';
import { Creators as DeliveryActions } from '../ducks/deliveryService';

export function* placeDeliveryOrder({ payload: order }) {
  yield put(DeliveryActions.addQuoteSpinner(order.id));

  try {
    const { data } = yield call(api.post, `/orders/${order.id}/delivery`);

    yield put(DeliveryActions.placeDeliveryOrderSuccess(data));
  } catch (error) {
    if (error?.response?.data?.message) {
      message.warn(error.response.data.message);
    }

    yield put(DeliveryActions.placeDeliveryOrderFailed());
  }

  yield put(DeliveryActions.removeQuoteSpinner(order.id));
}

export function* cancelDeliveryOrder({ payload: order }) {
  try {
    const { data } = yield call(api.post, `/orders/${order.id}/delivery/cancel`);

    yield put(DeliveryActions.cancelDeliveryOrderSuccess(data));
  } catch (error) {
    yield put(DeliveryActions.cancelDeliveryOrderFailed(error.message));
  }
}

export function* geoLocation({ payload: order }) {
  try {
    const { data } = yield call(api.get, `/restaurants/${order.restaurant.id}/orders/${order.id}/geolocation`);

    const { modalGeoLocationClosed } = yield select((state) => state.delivery);

    if (!modalGeoLocationClosed) {
      data.id = order.id;
      yield put(DeliveryActions.setGeoLocation(data));
    }
  } catch (error) {
    message.error('Houve um erro ao retornar a localização.');
    yield put(DeliveryActions.resetGeoLocation());
  }
}
