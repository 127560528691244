import styled from 'styled-components';
import { Layout } from 'antd';
import media from 'styled-media-query';

export const Wrapper = styled(Layout)`
  padding: 30px;
  flex: 1;
  height: 100%;

  .ant-card-body {
    ${media.lessThan('small')`
      padding: 5px;
    `}
  }
`;
